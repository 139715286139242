/** @format */

import { IconButton } from '@material-ui/core'
import { GetApp } from '@material-ui/icons'
import { MTableToolbar } from 'material-table'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import requests from '../../api/api'
import ChipsFilters from '../../components/common/ChipsFilters'
import FilterableTable from '../../components/common/FilterableTable'
import LoadingOverlay from '../../components/common/LoadingOverlay'
import { AppContext } from '../../stores/AppStore'
import { PlppColumn, PlppContext } from '../../stores/PlppStore'
import { UserContext } from '../../stores/UserStore'
import { getAllKeys } from '../../utils/columnsOrderingUtils'
import { sortByPosition } from '../../utils/sort-utils'
import PopoverFilter from './PopoverFilter'

const PlppDataTable = (props: { switchToTestView: () => void }) => {
	const { pageLoading, setPageLoading } = useContext(AppContext)
	const { pageLoadingFomDrawer } = useContext(AppContext)
	const { plppState, plppDispatch } = useContext(PlppContext)
	const { currentUser, setAuthState } = useContext(UserContext)
	const { t } = useTranslation()
	const [selectedItemIds, setSelectedItemIds] = useState<string[]>(plppState.selectedItemIds)

	let initData: any[] = []
	let globalData: any[] = []
	let initTestsData: any[] = []
	let globalTestsData: any[] = []

	let defaultVisibleColumns: string[] = []

	const downloadCertificate = (valid?: string, tallyNumber?: string, orderNumber?: string, itemNumber?: string) => {
		setPageLoading(true)
		if (valid) {
			requests
				.getCertificatePlppData(valid)
				.then(data => {
					window.open(data)
				})
				.finally(() => {
					setPageLoading(false)

					// Scroll to the clicked button
					let rows = Array.from(document.getElementsByTagName('tr'))
					for (let i = 0; i < rows.length; i++) {
						if (rows[i].textContent.includes(valid)) {
							let columns = Array.from(rows[i].getElementsByTagName('td'))
							for (let y = 0; y < columns.length; y++) {
								if (columns[y].getElementsByTagName('button').length > 0) {
									columns[y].scrollIntoView()
									break
								}
							}
							break
						}
					}
				})
				.catch(e => {
					setPageLoading(false)
					if (e.resultCode === 'error.expired.token') {
						setAuthState('LOGIN')
					} else {
						toast.error(t('plpp-data.noCertificate').toString())
					}
				})
		} else if (tallyNumber && orderNumber && itemNumber) {
			requests
				.getCertificatePlppDataByTally(tallyNumber, orderNumber, itemNumber)
				.then(data => {
					window.open(data)
				})
				.finally(() => {
					setPageLoading(false)

					// Scroll to the clicked button
					let rows = Array.from(document.getElementsByTagName('tr'))
					for (let i = 0; i < rows.length; i++) {
						if (rows[i].textContent.includes(valid)) {
							let columns = Array.from(rows[i].getElementsByTagName('td'))
							for (let y = 0; y < columns.length; y++) {
								if (columns[y].getElementsByTagName('button').length > 0) {
									columns[y].scrollIntoView()
									break
								}
							}
							break
						}
					}
				})
				.catch(e => {
					setPageLoading(false)
					if (e.resultCode === 'error.expired.token') {
						setAuthState('LOGIN')
					} else {
						toast.error(t('plpp-data.noCertificate').toString())
					}
				})
		}
	}

	const retrieveColumnsToDisplay = () => {
		return requests
			.getAllTemplatesByUserData(currentUser.id)
			.then(templates => {
				const defaultTemplate = templates.find(template => template.name === 'Default template')
				if (defaultTemplate) {
					defaultVisibleColumns = defaultTemplate.carbonSteelColumns
				} else {
					// Retrieve default selected columns from ColumnsOrdering
					defaultVisibleColumns = getAllKeys()
						.filter(key => key.checkedByDefault === true)
						.map(key => key.label)
				}

				// Dispatch the list of selected columns in the context
				plppDispatch({
					type: 'set_selected_plpp_columns',
					selectedColumns: defaultVisibleColumns
				})
				if(defaultTemplate?.id){
                    plppDispatch({
                        type: 'set_selected_template_id',
                        selectedTemplateId: defaultTemplate.id
                    })
				}
				plppDispatch({
					type: 'on_template_update'
				})
			})
			.catch(e => {
				if (e.resultCode === 'error.expired.token') {
					setAuthState('LOGIN')
				} else {
					toast.error(e.message)
				}
			})
	}

	const getPlppDataByPacket = (
		current: number,
		total: number,
		packetSize: number,
		buffer: any[],
		selectedItemIds?: string[]
	) => {
		return requests.getPlppDataOffset(current, packetSize, selectedItemIds).then(nextCall => {
			current = current + nextCall[0].length
			buffer = buffer.concat(nextCall[0])
			if (current < total) {
				return getPlppDataByPacket(current, total, packetSize, buffer, selectedItemIds)
			} else {
				return selectedItemIds == null ? treatAllPlppDataResult(buffer) : treatPlppDataResult(buffer)
			}
		})
	}

	const getTestsPlppDataByPacket = (
		current: number,
		total: number,
		packetSize: number,
		buffer: any[],
		selectedItemIds?: string[]
	) => {
		return requests
			.getTestsPlppDataOffset(current, packetSize, selectedItemIds)
			.then(nextCall => {
				current = current + nextCall[0].length
				buffer = buffer.concat(nextCall[0].map(carbonSteelHeat => ({ carbonSteelHeat })))
				if (current < total) {
					return getTestsPlppDataByPacket(current, total, packetSize, buffer, selectedItemIds)
				} else {
					return selectedItemIds == null ? treatAllTestsPlppDataResult(buffer) : treatTestsPlppDataResult(buffer)
				}
			})
			.finally(() => {
				plppDispatch({
					type: 'set_tests_data_to_display_have_been_loaded'
				})
			})
	}

	const treatAllPlppDataResult = (data: any[]) => {
		if (data.length > 0) {
			globalData = globalData.concat(data)
			plppDispatch({
				type: 'set_all_plpp_data',
				allPlppData: globalData
			})
		}
	}

	const treatAllTestsPlppDataResult = (data: any[]) => {
		if (data.length > 0) {
			globalTestsData = globalTestsData.concat(data)
			plppDispatch({
				type: 'set_all_tests_plpp_data',
				allTestsPlppData: globalTestsData
			})
		}
	}

	const treatPlppDataResult = (data: any[]) => {
		if (data.length > 0) {
			initData = initData.concat(data)
			plppDispatch({
				type: 'set_displayed_plpp_data',
				displayedPlppData: initData
			})
			setPageLoading(false)
			return
		}
	}

	const treatTestsPlppDataResult = (data: any[]) => {
		if (data.length > 0) {
			initTestsData = initTestsData.concat(data)
			plppDispatch({
				type: 'set_displayed_tests_plpp_data',
				displayedTestsPlppData: initTestsData
			})
			return
		}
	}

	const onFilterChange = (key: string) => {
		return (columnId: any, paramFilter: any) => {
			const min = paramFilter.min ? paramFilter.min : ''
			const max = paramFilter.max ? paramFilter.max : ''
			const splitValues = paramFilter.value ? paramFilter.value.split('|') : ''

			if (paramFilter.clear || (min === '' && max === '' && splitValues === '')) {
				plppDispatch({
					type: 'remove_filter_from_columns_map',
					filterToRemoveFromPipeView: key,
					filterToRemoveFromTestView: undefined
				})
			} else {
				// construct the filtered map column
				let filteredColumnsMap = new Map()
				filteredColumnsMap.set(key, {
					values: splitValues,
					min: min,
					max: max
				})

				plppDispatch({
					type: 'set_filtered_columns_map',
					filteredColumnsMap,
					filteredTestsColumnsMap: new Map()
				})
			}
		}
	}

	const getPlppData = (isFirstLoading: boolean) => {
		requests
			.getPlppData(selectedItemIds)
			.then(data => {
				// Retrieve columns to be displayed
				return retrieveColumnsToDisplay().then(() => {
					const total = data[1]
					let current = data[0].length
					const packetSize = 1000
					let buffer = data[0]

					return getPlppDataByPacket(current, total, packetSize, buffer, selectedItemIds)
				})
			})
			.finally(() => {
				setPageLoading(false)
				if (isFirstLoading) {
					requests.getPlppData().then(data => {
						const total = data[1]
						let current = data[0].length
						const packetSize = 1000
						let buffer = data[0]
						getPlppDataByPacket(current, total, packetSize, buffer)
					})
				}
			})
			.catch(e => {
				if (e.resultCode === 'error.expired.token') {
					setAuthState('LOGIN')
				} else {
					toast.error(e.message)
				}
			})
	}

	const getTestsPlppData = (isFirstLoading: boolean) => {
		const packetSize = 1000
		plppDispatch({ type: 'set_is_finished_loading_tests_plpp_data', isFinishedLoadingTestsPlppData: false })
		requests
			.getTestsPlppDataOffset(0, packetSize, selectedItemIds)
			.then(data => {
				const total = data[1]
				const current = data[0].length
				let buffer = data[0].map(carbonSteelHeat => ({ carbonSteelHeat }))

				return getTestsPlppDataByPacket(current, total, packetSize, buffer, selectedItemIds)
			})
			.finally(() => {
				plppDispatch({ type: 'set_is_finished_loading_tests_plpp_data', isFinishedLoadingTestsPlppData: true })
				if (isFirstLoading) {
					requests.getTestsPlppDataOffset(0, packetSize).then(data => {
						const total = data[1]
						let current = data[0].length
						let buffer = data[0].map(carbonSteelHeat => ({ carbonSteelHeat }))
						getTestsPlppDataByPacket(current, total, packetSize, buffer)
					})
				}
			})
			.catch(e => {
				if (e.resultCode === 'error.expired.token') {
					setAuthState('LOGIN')
				} else {
					toast.error(e.message)
				}
			})
	}

	useEffect(() => {
		if (selectedItemIds.length > 0 && (plppState.isFirstLoading || plppState.isNeedToRefreshPlppData)) {
			setPageLoading(true)
			plppDispatch({ type: 'set_is_first_loading', isFirstLoading: false })
			plppDispatch({ type: 'set_is_need_to_refresh_plpp_data', isNeedToRefreshPlppData: false })
			plppDispatch({ type: 'reset_is_need_to_refresh_plpp_columns' })
			getPlppData(plppState.isFirstLoading)
			getTestsPlppData(plppState.isFirstLoading)
		} else if (selectedItemIds.length > 0 && plppState.isNeedToRefreshPlppColumns > 0) {
			plppDispatch({ type: 'reset_is_need_to_refresh_plpp_columns' })
		}
	}, [
		selectedItemIds,
		plppState.isNeedToRefreshPlppColumns // Refresh the table when user changes something in ManageColumns
	])

	useEffect(() => {
		setSelectedItemIds([...plppState.selectedItemIds])
	}, [plppState.selectedItemIds])

	useEffect(() => {}, [pageLoading, pageLoadingFomDrawer])

	useEffect(() => {
		// CSS to stick header, filter and tally column
		const array = Array.from(document.getElementsByClassName('MuiTableRow-root'))
		array.forEach((lineElement, lineIndex) => {
			const isHeaderElement = lineElement.getElementsByTagName('th').length > 0
			const isFilterElement = lineIndex === 1
			const rowElements = isHeaderElement
				? lineElement.getElementsByTagName('th')
				: lineElement.getElementsByTagName('td')

			// CSS to stick header and filter
			if (isHeaderElement || isFilterElement) {
				Array.from(rowElements).forEach(element => {
					if (isHeaderElement || isFilterElement) {
						element.style.position = 'sticky'
						element.style.top = lineIndex === 0 ? '0px' : (array[0] as HTMLElement).offsetHeight + 'px'
						element.style.zIndex = '998'
						element.style.background = '#FFFFFF'
					}
				})
			}

			// CSS to stick tally column : It's always the first column
			const tallyElement = rowElements[1]
			if (tallyElement) {
				tallyElement.style.position = 'sticky'
				tallyElement.style.left = '0'
				tallyElement.style.zIndex = isHeaderElement || isFilterElement ? '999' : '998'
				tallyElement.style.backgroundColor =
					tallyElement.parentElement.style.backgroundColor === 'rgba(199, 225, 191, 0.5)'
						? 'rgb(227,240,223)'
						: '#FFFFFF'
				tallyElement.style.transition = 'all 300ms ease 0s'
			}
		})
	})

	const getColumnsToDisplay = () => {
		const columnsToDisplay: PlppColumn[] = plppState.plppColumns
			.filter(key => key.hasSpecificDisplay === false)
			.filter(column => {
				let isEmpty = false
				isEmpty =
					plppState.displayedPlppData.filter(data => {
						const keys = column.key.split('.')
						let value = data
						keys.forEach(function (key) {
							if (value != null) {
								value = value[key]
							}
						})
						return value !== null && value !== undefined && value !== ''
					}).length === 0
				return (
					column.isMandatoryPipeView ||
					(column.visible &&
						column.isForPipeView &&
						// Add EXCEPTION for certificate download column
						((!plppState.showEmptyColumns && !isEmpty) ||
							column.key === 'carbonSteelCertificate.downloadCertificateButton' ||
							plppState.showEmptyColumns))
				)
			})
			.sort((a, b) => sortByPosition(a, b))
			.map(col => {
				return {
					...col,
					filterComponent: props => (
						<PopoverFilter
							{...props}
							isPipeView={true}
							isAlphanumeric={t('plpp-data.' + col.key + '.filter') === 'alphanumeric'}
							onFilterChanged={onFilterChange(col.key)}
							columnTitle={col.key}
						/>
					),
					title: t('plpp-data.' + col.key + '.name')
				}
			})
        if(plppState.plppColumns.find(column => column.key === 'carbonSteelCertificate.downloadCertificateButton').visible){
            columnsToDisplay.push({
    			title: t('plpp-data.downloadCertificate'),
    			key: 'carbonSteelCertificate.downloadCertificateButton',
    			isForPipeView: true,
    			isForTestView: false,
    			visible: true,
    			position: plppState.plppColumns.findIndex(
    				column => column.key === 'carbonSteelCertificate.downloadCertificateButton'
    			),
    			render: (rowData: any) => (
    				<IconButton
    					onClick={() => {
    						downloadCertificate(
    							rowData.valid,
    							rowData.tallyNumber,
    							rowData.carbonSteelItemPlpp && rowData.carbonSteelItemPlpp.carbonSteelOrder
    								? rowData.carbonSteelItemPlpp.carbonSteelOrder.vallourecOrderReference
    								: undefined,
    							rowData.carbonSteelItemPlpp ? rowData.carbonSteelItemPlpp.vallourecItemReference : undefined
    						)
    					}}
    					data-testid="downloadButton"
    				>
    					<GetApp />
    				</IconButton>
    			)
    		})
        }


		return columnsToDisplay
	}

	const getDataToDisplay = () => {
		return plppState.displayedPlppData
	}

	const setSelectedRows = (selectedPlppData: any[]) => {
		plppDispatch({ type: 'set_selected_plpp_data', selectedPlppData })
	}

	const onColumnDragged = (sourceIndex, destinationIndex) => {
		const columnsToDisplay: PlppColumn[] = getColumnsToDisplay()
		const sourceColumn: PlppColumn = columnsToDisplay[sourceIndex]
		const destinationColumns: PlppColumn = columnsToDisplay[destinationIndex]
		plppDispatch({
			type: 'move_columns',
			sourceIndex: plppState.plppColumns.find(col => col.key === sourceColumn.key).position,
			targetIndex: plppState.plppColumns.find(col => col.key === destinationColumns.key).position,
			columnsToMove: [sourceColumn]
		})
	}

	return (
		<div>
			<LoadingOverlay open={pageLoading || pageLoadingFomDrawer} />
			<FilterableTable
				columns={getColumnsToDisplay()}
				data={getDataToDisplay()}
				currentPageNumber={plppState.pageNumber}
				setCurrentPageNumber={(pageNumber: number) => plppDispatch({ type: 'set_page_number', pageNumber })}
				autoResetFilters={false}
				draggable={true}
				onColumnDragged={onColumnDragged}
				currentPageSize={plppState.pageSize}
				setCurrentPageSize={(pageSize: number) => plppDispatch({ type: 'set_page_size', pageSize })}
				title={<ChipsFilters isPipeView={true} filters={plppState.filteredColumnsMap} />}
				components={{
					Toolbar: propsToolbar => (
						<div>
							<MTableToolbar {...propsToolbar} />
						</div>
					)
				}}
				selectedData={plppState.selectedPlppData}
				setSelectedRows={setSelectedRows}
			/>
		</div>
	)
}

export default PlppDataTable
