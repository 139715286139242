/** @format */

const PerformActionOrDisplayPanel = (currentUser, plppState, plppDispatch, callback: Function) => {
	if (
		currentUser.neverAskMeForColumnsOrder === false &&
		plppState.columnsOrderHasChanged > 0 &&
		sessionStorage.getItem('DONT_ASK_SAVE_COLUMNS') === null
	) {
		plppDispatch({ type: 'display_save_columns_order_panel', display: true })
	} else {
		callback()
	}
}

export default PerformActionOrDisplayPanel
