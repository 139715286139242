export const formatDataForDisplay = (data) => {
  data.forEach((d: object) => {
    Object.keys(d).forEach((key: string) => {
      if (key.indexOf("In") === key.length - 2) {
        //It has a key ending with "In"
        let fieldIn = d[key.toString()];
        let field = d[key.replace("In", "")];
        if (
          fieldIn &&
          (field === "" || field === null || field === undefined)
        ) {
          d[key.replace("In", "")] = d[key.toString()];
        }
      }
      if (key.indexOf("Lb") === key.length - 2) {
        //It has a key ending with "In"
        let fieldIn = d[key.toString()];
        let field = d[key.replace("Lb", "")];
        if (
          fieldIn &&
          (field === "" || field === null || field === undefined)
        ) {
          d[key.replace("Lb", "")] = d[key.toString()];
        }
      }
    });
  });
  return data;
};
