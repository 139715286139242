/** @format */

import {
	Box,
	createStyles,
	Drawer,
	FormControl,
	FormControlLabel,
	InputLabel,
	makeStyles,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	TextField,
	Theme,
	Typography
} from '@material-ui/core'
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined'
import React, { useCallback, useContext, useEffect } from 'react'
import requests from '../../api/api'
import { toast } from 'react-toastify'
import Button from '../../components/common/Button'
import IconInformation from '../../components/icon/IconInformation'
import { AppContext } from '../../stores/AppStore'
import { getColumnsToSaveInTemplate, PlppContext } from '../../stores/PlppStore'
import { UserContext } from '../../stores/UserStore'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		drawer: {
			width: '430px',
			height: '1170px',
			background: 'var(--primary-white-ffffff) 0% 0% no-repeat padding-box',
			// background: '#FFFFFF 0% 0% no-repeat padding-box',
			boxShadow: '-4px 0px 8px #00000029',
			textAlign: 'center',
			opacity: '1',
			padding: '32px 24px'
		},
		title: {
			width: '382px',
			height: '26px',
			// color: 'var(--primary-navy-172983)',
			textAlign: 'center',
			font: 'normal 20px/24px Roboto',
			letterSpacing: '0px',
			color: '#1A2981',
			opacity: '1',
			paddingTop: '8px'
		},
		description: {
			width: '382px',
			textAlign: 'left',
			font: 'normal 16px/24px Roboto',
			letterSpacing: '0px',
			color: '#000000',
			opacity: '1',
			paddingTop: '16px'
		},
		formControl: {
			padding: '16px 16px 16px 0',
			textAlign: 'left',
			display: 'flex'
		},
		formControlTextInput: {
			paddingTop: '16px',
		},
		radio: {
			'&.MuiRadio-colorSecondary.Mui-checked': {
				color: '#1A2981'
			}
		},
		menuItem: {
			height: '50px'
		},
		button: {
			marginTop: '32px',
			color: 'white',
			background: 'transparent linear-gradient(90deg, #00CDFB 0%, #009EDC 100%) 0% 0% no-repeat padding-box',
			'&:disabled': {
				color: 'white',
				background: '#00000029'
			}
		},
		buttonIcon: {
			marginRight: '8px'
		}
	})
)

/**
 * Drawer component
 */
const SaveColumnsDrawer = (props: { callback: Function }) => {
	const { plppState, plppDispatch } = useContext(PlppContext)
	const { setPageLoadingFomDrawer } = useContext(AppContext)
	const { currentUser, setAuthState } = useContext(UserContext)

	const classes = useStyles()
	const { t } = useTranslation()

	const [selectedRadio, setSelectedRadio] = React.useState('createNewTemplate')
	const [selectedItem, setSelectedItem] = React.useState('')

	const [selectLabel, setSelectLabel] = React.useState('Template name')
	const [selectValues, setSelectValues] = React.useState([])
	const [templateList, setTemplateList] = React.useState([])
	const [selectDisabled, setSelectDisabled] = React.useState(true)
	const [buttonLabel, setButtonLabel] = React.useState('Create template')
	const [buttonDisabled, setButtonDisabled] = React.useState(true)

	useEffect(() => {
	    if(selectedRadio === 'updateTemplate'){
	    	setSelectValues(templateList)
	    }
	},[templateList])
	const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setButtonDisabled(true)

		const selectedRadio = (event.target as HTMLInputElement).value
		setSelectedRadio(selectedRadio)
		switch (selectedRadio) {
			case 'createNewTemplate':
				setSelectedItem('')
				setSelectLabel(t('plpp-data.saveColumnsDrawer.selectLabels.templateName'))
				setSelectDisabled(true)
				setSelectValues([])
				setButtonLabel(t('plpp-data.saveColumnsDrawer.buttonLabels.create'))
				break
			case 'updateTemplate':
				setSelectedItem('')
				setSelectLabel(t('plpp-data.saveColumnsDrawer.selectLabels.selectExistingTemplate'))
				setSelectDisabled(false)
				setSelectValues(templateList)
				setButtonLabel(t('plpp-data.saveColumnsDrawer.buttonLabels.update'))
				break
			case 'dontApply':
				setSelectLabel(t('plpp-data.saveColumnsDrawer.selectLabels.value'))
				setSelectDisabled(false)
				setSelectValues([
					{ key: '1', value: t('plpp-data.saveColumnsDrawer.selectValues.dontAskCurrent') },
					{ key: '2', value: t('plpp-data.saveColumnsDrawer.selectValues.dontAskSession') },
					{ key: '3', value: t('plpp-data.saveColumnsDrawer.selectValues.neverAsk') }
				])
				setButtonLabel(t('plpp-data.saveColumnsDrawer.buttonLabels.save'))
				break
		}
	}

	const saveTemplate = useCallback(
		(methodToSave, templateToSave, callback) => {
			setPageLoadingFomDrawer(true)

			return methodToSave(templateToSave)
				.then(newTemplate => {
					return requests.getAllTemplatesByUserData(currentUser.id).then(data => {
						plppDispatch({
							type: 'set_all_templates',
							allTemplates: data
						})
						plppDispatch({
							type: 'set_selected_template_id',
							selectedTemplateId: newTemplate.id
						})
						plppDispatch({
							type: 'set_selected_plpp_columns',
							selectedColumns: newTemplate.carbonSteelColumns
						})

						plppDispatch({
							type: 'reset_columns_order_has_changed'
						})

						plppDispatch({
							type: 'on_template_update'
						})

						// Display success toast
						toast.success(t('plpp-data.templateSaved', { name: templateToSave.name }))

                        if (callback) {
                            // Hide the panel
                            closeDrawer()
                            // Launch the callback
                            callback()
                        }
					})
				})
				.finally(() => {
					setPageLoadingFomDrawer(false)
				})
				.catch(e => {
					if (e.resultCode === 'error.expired.token') {
						setAuthState('LOGIN')
					} else {
						toast.error(e.message)
					}
				})
		},
		[plppDispatch, setAuthState, currentUser]
	)

	const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setButtonDisabled(false)
		setSelectedItem((event.target as HTMLInputElement).value)
	}

	const checkTextInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault()
		setSelectedItem((event.target as HTMLInputElement).value)
		setButtonDisabled(event.target.value === undefined || event.target.value === '')
	}

	const onButtonClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		let templateToSave = null
		switch (selectedRadio) {
			case 'createNewTemplate':
				templateToSave = {
					name: selectedItem,
					user: { cognitoId: currentUser.id },
					carbonSteelColumns: getColumnsToSaveInTemplate(plppState)
				}
				saveTemplate(requests.createTemplate, templateToSave, props.callback)
				break
			case 'updateTemplate':
				templateToSave = {
					...plppState.allTemplates.find(template => template.name === selectedItem),
					carbonSteelColumns: getColumnsToSaveInTemplate(plppState)
				}
				saveTemplate(requests.updateTemplate, templateToSave, props.callback)
				break
			case 'dontApply':
				switch (selectedItem) {
					case '2':
						// Case 'Don't ask for this session': save the information in session storage
						sessionStorage.setItem('DONT_ASK_SAVE_COLUMNS', 'true')
						break
					case '3':
						// Case 'Never ask me': save the information on server side
						onNeverAskMeSelected()
						break
				}
				// Hide the panel
                closeDrawer()
				// Launch the callback
				props.callback()

				plppDispatch({
					type: 'reset_columns_order_has_changed'
				})
				break
		}
	}

	const onNeverAskMeSelected = () => {
		setPageLoadingFomDrawer(true)
		requests.saveNeverAskMeForColumnsOrder().finally(setPageLoadingFomDrawer(false))
		currentUser.neverAskMeColumnsOrder = true
	}

	const saveDefaultTemplate = useCallback(() => {
		const defaultTemplate = {
			name: 'Default template',
			user: { cognitoId: currentUser.id },
			carbonSteelColumns: []
		}
		return saveTemplate(requests.createTemplate, defaultTemplate, null)
	}, [saveTemplate, currentUser])

	useEffect(() => {
		if (currentUser) {
			if (plppState.selectedTemplateId) {
				// Refresh the list of templates
					requests
						.getAllTemplatesByUserData(currentUser.id)
						.then(async templates => {
							setTemplateList(
								templates.map(t => {
									return { key: t.name, value: t.name }
								})
							)
						})
						.catch(e => {
							if (e.resultCode === 'error.expired.token') {
								setAuthState('LOGIN')
							} else {
								toast.error(e.message)
							}
						})
			} else {
				setPageLoadingFomDrawer(true)
				requests
					.getAllTemplatesByUserData(currentUser.id)
					.then(async templates => {
						let defaultTemplate = templates.find(template => template.name === 'Default template')

						if (defaultTemplate) {
							plppDispatch({
								type: 'set_all_templates',
								allTemplates: templates
							})
							plppDispatch({
								type: 'set_selected_template_id',
								selectedTemplateId: defaultTemplate.id
							})
							plppDispatch({
								type: 'set_selected_plpp_columns',
								selectedColumns: defaultTemplate.carbonSteelColumns
							})
						} else {
							// Save default template if not existing
							await saveDefaultTemplate()
						}
						setTemplateList(
							templates.map(t => {
								return { key: t.name, value: t.name }
							})
						)
					})
					.finally(() => setPageLoadingFomDrawer(false))
					.catch(e => {
						if (e.resultCode === 'error.expired.token') {
							setAuthState('LOGIN')
						} else {
							toast.error(e.message)
						}
					})
			}
		} else {
			setAuthState('LOGIN')
		}
	}, [plppState.displaySaveColumnsOrderPanel, saveDefaultTemplate, plppState.selectedTemplateId])

	/**
	 * Close the drawer when clicking outside or pressing Escape
	 */
	function closeDrawer() {
		plppDispatch({ type: 'display_save_columns_order_panel', display: false })
	}

	return (
		<Drawer
			open={plppState.displaySaveColumnsOrderPanel}
			ModalProps={{
				keepMounted: true
			}}
			anchor="right"
			onClose={closeDrawer}
		>
			<Box className={classes.drawer}>
				<IconInformation />
				<Typography variant="h1" className={classes.title}>
					{t('plpp-data.saveColumnsDrawer.title')}
				</Typography>
				<Typography className={classes.description}>{t('plpp-data.saveColumnsDrawer.description')}</Typography>
				<FormControl className={classes.formControl}>
					<RadioGroup defaultValue="createNewTemplate" name="radio-buttons-group" onChange={handleRadioChange}>
						<FormControlLabel
							value="createNewTemplate"
							control={<Radio size="small" className={classes.radio} />}
							label={t('plpp-data.saveColumnsDrawer.radioOptions.createNewTemplate')}
						/>
						<FormControlLabel
							value="updateTemplate"
							control={<Radio size="small" className={classes.radio} />}
							label={t('plpp-data.saveColumnsDrawer.radioOptions.updateTemplate')}
						/>
						<FormControlLabel
							value="dontApply"
							control={<Radio size="small" className={classes.radio} />}
							label={t('plpp-data.saveColumnsDrawer.radioOptions.dontApply')}
						/>
					</RadioGroup>
				</FormControl>
				{selectDisabled ? (
					<TextField className={classes.formControlTextInput} label={selectLabel} onChange={checkTextInput} value={selectedItem} fullWidth />
				) : (
					<FormControl className={classes.formControl} disabled={selectDisabled} variant="standard">
						<InputLabel id="select_label">{selectLabel}</InputLabel>
						<Select
							labelId="select_label"
							className={classes.formControl}
							label={selectLabel}
							onChange={handleSelectChange}
						>
							{selectValues.map(option => {
								return (
									<MenuItem value={option.key} className={classes.menuItem}>
										{option.value}
									</MenuItem>
								)
							})}
						</Select>
					</FormControl>
				)}

				<Button disabled={buttonDisabled} variant="contained" className={classes.button} onClick={onButtonClick}>
					<SaveOutlinedIcon className={classes.buttonIcon} /> {buttonLabel}
				</Button>
			</Box>
		</Drawer>
	)
}

export default SaveColumnsDrawer
