import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Switch from '@material-ui/core/Switch';
import { PlppContext } from '../../stores/PlppStore'

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      enabled: {
        fontWeight:'bold',
      },
      disabled: {
        fontWeight:'normal',
        color:'lightgrey'
      },
      grid: {
         height: '400',
         maxHeight: '400'
      },
   })
);

const DisplayEmptyColumnsSwitch = () => {
   const classes = useStyles();
   const { plppState, plppDispatch } = useContext(PlppContext)

   const { t } = useTranslation();

	const handleChange = (event: React.SyntheticEvent) => {
		plppDispatch({ type: 'set_show_empty_columns', showEmptyColumns: !plppState.showEmptyColumns })
	}

   return (
       <Grid container alignItems="center" style={{ width: 'auto' }}>
             <Grid item
			    className={!plppState.showEmptyColumns ? classes.enabled : classes.disabled}>
				{t('hide')}
             </Grid>
             <Grid item>
               <Switch
                 id="switch-show-empty-columns"
                 color="primary"
                 checked={plppState.showEmptyColumns}
                 onChange={handleChange}
               />
             </Grid>
             <Grid item
             style={{fontWeight: plppState.showEmptyColumns ? 'bold' : 'normal'}}
			className={plppState.showEmptyColumns ? classes.enabled : classes.disabled}>
                {t('showEmptyColumns')}
             </Grid>
       </Grid>
   );

};


export default DisplayEmptyColumnsSwitch;

