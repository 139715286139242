/** @format */

import { CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'

import { ToastContainer, Zoom } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import './i18n'

import { AppProvider } from './stores/AppStore'
import { LoadProvider } from './stores/LoadStore'
import { OctgProvider } from './stores/OctgStore'
import { PlppProvider } from './stores/PlppStore'
import { UserProvider } from './stores/UserStore'

import Router from './Router'
import smartengoTheme from './utils/theme'

const App = () => {
	return (
		<ThemeProvider theme={smartengoTheme}>
			<ToastContainer
				autoClose={4000}
				draggable={false}
				transition={Zoom}
				hideProgressBar={true}
				closeButton={true}
			/>
			<CssBaseline />
			<AppProvider>
				<UserProvider>
					<LoadProvider>
						<PlppProvider>
							<OctgProvider>
								<Router />
							</OctgProvider>
						</PlppProvider>
					</LoadProvider>
				</UserProvider>
			</AppProvider>
		</ThemeProvider>
	)
}

export default App
