import ColumnsOrdering, { SectionItem } from "../containers/plpp/manageColumns/ColumnsOrdering"

/**
 * 
 * @returns All keys defined in ColumnsOrdering
 */
export const getAllKeys =  () : SectionItem[] => {
    return ColumnsOrdering.sections.flatMap(section => {
    return section.keys
        .flatMap(key => {
            if (key.keys && key.keys.length > 0) {
                return key.keys
            } else {
                return key
            }
        })
    })
    .map((value, index) => {
        value.visible = value.checkedByDefault
        value.position = index
        return value;
    })
}