/** @format */

import {
	Box,
	Button,
	CardActionArea,
	CardMedia,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	FormControl,
	FormControlLabel,
	Icon,
	IconButton,
	Radio,
	RadioGroup
} from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import CancelIcon from '@material-ui/icons/Cancel'
import { Workbook } from 'exceljs'
import FileSaver from 'file-saver'
import moment from 'moment'
import { default as React, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import requests from '../../../api/api'
import exportFile from '../../../assets/icons/export-file.svg'
import PDEFSvg from '../../../assets/icons/noun_file_pdef.svg'
import XLSSvg from '../../../assets/icons/xls.svg'
import { AppContext } from '../../../stores/AppStore'
import { PlppContext } from '../../../stores/PlppStore'
import { UserContext } from '../../../stores/UserStore'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		openModalButton: {
			color: 'white',
			background: 'transparent linear-gradient(90deg, #00CDFB 0%, #009EDC 100%) 0% 0% no-repeat padding-box'
		},
		exportFileIcon: {
			filter: 'invert(100%) sepia(17%) saturate(2%) hue-rotate(219deg) brightness(104%) contrast(101%)'
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			position: 'absolute',
			top: '25%',
			left: '25%',
			width: '50%',
			padding: theme.spacing(2, 4, 3)
		},
		form_control_label: {
			height: '126px',
			width: '118px',
			padding: '16px'
		},
		card_media: {
			height: '94px',
			width: '86px'
		},
		exportButton: {
			width: '300px',
			margin: '20px 4px 0px 4px',
			color: 'white'
		}
	})
)

const DisplayExportModalButton = () => {
	const classes = useStyles()
	const { t } = useTranslation()

	const { setPageLoading } = useContext(AppContext)
	const { plppState } = useContext(PlppContext)
	const { setAuthState } = useContext(UserContext)

	const [open, setOpen] = React.useState(false)
	const [openDialogSelectOnePipe, setOpenDialogSelectOnePipe] = React.useState(false)
	const [openDialogSelectTooManyPipe, setOpenDialogSelectTooManyPipe] = React.useState(false)
	const [selectedValue, setSelectedValue] = React.useState('xls')

	const handleModalOpen = () => {
		setOpen(true)
	}

	const handleModalClose = () => {
		setOpen(false)
	}

	const handleChange = (value: string) => {
		setSelectedValue(value)
	}

	const onClickOnExportButton = (all: boolean) => {
		if (!all && plppState.selectedPlppData.length === 0) {
			setOpenDialogSelectOnePipe(true)
		} else if (!all && plppState.selectedPlppData.length > 1000) {
			setOpenDialogSelectTooManyPipe(true)
		} else {
			if (selectedValue === 'xls') {
				downloadAtXLSXFormat(all)
			} else {
				downloadAtPDEFFormat(all)
			}
		}
	}

	const downloadAtXLSXFormat = async (all: boolean) => {
		const workbook = new Workbook()
		const worksheetPipeView = workbook.addWorksheet('PIPES VIEW')
		worksheetPipeView.columns = all
			? plppState.plppColumns
					.filter(column => column.isForPipeView)
					.map(column => ({
						header: t('plpp-data.' + column.key + '.name'),
						key: column.key
					}))
			: plppState.plppColumns
					.filter(column => column.isForPipeView && column.visible)
					.map(column => ({
						header: t('plpp-data.' + column.key + '.name'),
						key: column.key
					}))
		const dataToExport = all ? plppState.allPlppDataForSelectedOrderItems : plppState.selectedPlppData

		const plppData = dataToExport.map(data => flatObject(data))
		worksheetPipeView.addRows(plppData)

		const worksheetTestView = workbook.addWorksheet('TESTS VIEW')
		worksheetTestView.columns = all
			? plppState.plppColumns
					.filter(column => column.isForTestView)
					.map(column => ({
						header: t('plpp-data.' + column.key + '.name'),
						key: column.key
					}))
			: plppState.plppColumns
					.filter(column => column.isMandatoryTestView || (column.isForTestView && column.visible))
					.map(column => ({
						header: t('plpp-data.' + column.key + '.name'),
						key: column.key
					}))
		const testDataToExport = all ? plppState.allTestsPlppDataForSelectedOrderItems : plppState.displayedTestsPlppData

		const heatList = plppState.selectedPlppData.map(data => data.carbonSteelHeat.number)
		const testData = testDataToExport
			.map(data => flatObject(data))
			.filter(data => (all ? data : heatList.includes(data['carbonSteelHeat.number'])))
		worksheetTestView.addRows(testData)

		const buffer = await workbook.xlsx.writeBuffer()
		const data: Blob = new Blob([buffer], {
			type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
		})
		FileSaver.saveAs(data, `plpp-data_vallourec_${moment().format('YYYYMMDDHHmmss')}.xlsx`)
		setOpen(false)
	}

	const flatObject = (obj: any, prefix: string = '') => {
		const keys = Object.keys(obj)

		return keys.reduce((current: any, key: string) => {
			const value: any = obj[key.toString()]
			const newKey: string = prefix ? prefix + '.' + key : key

			return value && typeof value === 'object'
				? { ...current, ...flatObject(value, newKey) }
				: { ...current, [newKey]: value }
		}, {})
	}

	const downloadAtPDEFFormat = (all: boolean) => {
		setPageLoading(true)
		let pdefParam: any = {}
		pdefParam.isForAll = all
		if (all) {
			pdefParam.orderItems = plppState.allPlppDataForSelectedOrderItems
				.map(plppData => ({
					order: plppData.carbonSteelItemPlpp.carbonSteelOrder.vallourecOrderReference,
					item: plppData.carbonSteelItemPlpp.vallourecItemReference
				}))
				.filter(
					(value, index, self) => index === self.findIndex(t => t.order === value.order && t.item === value.item)
				)
		} else {
			pdefParam.tallies = plppState.selectedPlppData.map(plppData => ({
				order: plppData.carbonSteelItemPlpp.carbonSteelOrder.vallourecOrderReference,
				item: plppData.carbonSteelItemPlpp.vallourecItemReference,
				tally: plppData.tallyNumber
			}))
		}

		let socket
		requests
			.launchPDEFTask(pdefParam)
			.then(data => {
				socket = new WebSocket(process.env.REACT_APP_WEB_SOCKET_URL + '?uuid=' + data)
				socket.onopen = function (e) {
					console.log('socket on onopen')
				}

				socket.onmessage = function (event) {
					console.log(`[message] Data received from server: ${event.data}`)
					if (event.data === 'SUCCESS') {
						// get the generated json file
						requests.getPDEFLink(data).then(pdefLink => {
							//06-24-2022, Matheus Augusto talked with Maxime Felder and was oriented to disable eslint sast rules for non-literal argument and we need it to not be a non-literal argument
							// eslint-disable-next-line
							window.open(pdefLink)
						})
						socket.close()
					} else if (event.data === 'ERROR') {
						console.log('Error during process, close the socket')
						socket.close()
					}
				}

				socket.onclose = function (event) {
					if (event.wasClean) {
						console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`)
					} else {
						console.log(`[close] Connection died; code=${event.code}`)
					}
					setPageLoading(false)
				}

				socket.onerror = function (error) {
					console.log(`[error] ${error}`)
					socket.close()
				}
			})
			.finally(() => {})
			.catch(e => {
				setPageLoading(false)
				if (e.resultCode === 'error.expired.token') {
					setAuthState('LOGIN')
				} else {
					toast.error(e.message)
				}
			})
	}

	return (
		<div>
			<Button
				className={classes.openModalButton}
				endIcon={
					<Icon>
						<img src={exportFile} className={classes.exportFileIcon} height={20} alt="Logo" />
					</Icon>
				}
				onClick={handleModalOpen}
			>
				{t('plpp-data.export.title')}
			</Button>

			<Modal open={open} onClose={handleModalClose}>
				<div className={classes.paper}>
					<div onClick={() => setOpen(false)}>
						<IconButton aria-label="close" style={{ float: 'right' }}>
							<CancelIcon color="primary" />
						</IconButton>
					</div>
					<div>
						<h2 id="simple-modal-title">{t('plpp-data.export.titleModal')}</h2>
						<p id="simple-modal-description">{t('plpp-data.export.description')}</p>
						<Box id="export-form-control" display="flex" justifyContent="center" m={1} p={1} bgcolor="background.paper">
							<FormControl component="fieldset">
								<RadioGroup row aria-label="fileType" name="fileType" defaultValue="xls" value={selectedValue}>
									<FormControlLabel
										value="xls"
										onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
											handleChange(event.target.value)
										}
										control={<Radio color="primary" />}
										label={
											<div onClick={() => handleChange('xls')} className={classes.form_control_label}>
												<CardActionArea>
													<CardMedia className={classes.card_media} image={XLSSvg} title="XLS" />
												</CardActionArea>
											</div>
										}
										labelPlacement="bottom"
									/>
									<FormControlLabel
										value="json"
										onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
											handleChange(event.target.value)
										}
										control={<Radio color="primary" />}
										label={
											<div onClick={() => handleChange('json')} className={classes.form_control_label}>
												<CardActionArea>
													<CardMedia className={classes.card_media} image={PDEFSvg} title="JSON" />
												</CardActionArea>
											</div>
										}
										labelPlacement="bottom"
									/>
								</RadioGroup>
							</FormControl>
						</Box>
					</div>
					<Box display="flex" justifyContent="center" m={1} p={1} bgcolor="background.paper">
						<Button
							color="primary"
							variant="contained"
							className={classes.exportButton}
							onClick={() => onClickOnExportButton(true)}
						>
							{t('plpp-data.export.allData')}
						</Button>
						<Button
							color="primary"
							variant="contained"
							className={classes.exportButton}
							onClick={() => onClickOnExportButton(false)}
						>
							{t('plpp-data.export.selectedRows')}
						</Button>

						<Dialog
							open={openDialogSelectOnePipe}
							onClose={() => setOpenDialogSelectOnePipe(false)}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
						>
							<DialogContent>
								<DialogContentText id="alert-dialog-description">
									{t('plpp-data.export.selectOnePipe.description')}
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={() => setOpenDialogSelectOnePipe(false)} color="primary" autoFocus>
									{t('yes')}
								</Button>
							</DialogActions>
						</Dialog>

						<Dialog
							open={openDialogSelectTooManyPipe}
							onClose={() => setOpenDialogSelectTooManyPipe(false)}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
						>
							<DialogContent>
								<DialogContentText id="alert-dialog-description">
									{t('plpp-data.export.selectTooManyPipe.description')}
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={() => setOpenDialogSelectTooManyPipe(false)} color="primary" autoFocus>
									{t('yes')}
								</Button>
							</DialogActions>
						</Dialog>
					</Box>
				</div>
			</Modal>
		</div>
	)
}

export default DisplayExportModalButton
