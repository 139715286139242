/** @format */

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React, { useEffect } from 'react'

export type ConfirmationDialogProps = {
	button?: any
	title: string
	description: string
	positiveButtonText: string
	negativeButtonText: string
	positiveButtonCallback?: any
	negativeButtonCallback?: any
	mainDivStyle?: any
	isOpen?: boolean
  	hiddenButton?: boolean
	handleEsc?: Function
}

export default function ConfirmationDialog(props: ConfirmationDialogProps) {
	const [open, setOpen] = React.useState(props.isOpen || false)

	const handleClose = () => {
		if(props.handleEsc) {
			props.handleEsc()
		}
		setOpen(false)
	}

  useEffect(() => {
    setOpen(props.isOpen)
  }, [props.isOpen])

	return (
		<div style={props.mainDivStyle}>
			{props.hiddenButton ? null : <div onClick={() => setOpen(true)}>{props.button}</div>}
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{props.description}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={props.negativeButtonCallback ? props.negativeButtonCallback : handleClose}
						color="primary"
					>
						{props.negativeButtonText}
					</Button>
					<Button
						onClick={props.positiveButtonCallback ? props.positiveButtonCallback : handleClose}
						color="primary"
						autoFocus
					>
						{props.positiveButtonText}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}
