/** @format */

import ArrowDownward from '@material-ui/icons/ArrowDownward'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Search from '@material-ui/icons/Search'
import { makeStyles } from '@material-ui/styles'
import MaterialTable, { Icons } from 'material-table'
import React, { forwardRef } from 'react'

const tableIcons: Icons = {
	DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />)
}

const useStyles = makeStyles(() => ({
	'@global': {
		'*::-webkit-scrollbar': {
			height: '15px !important',
			scrollbarWidth: 'auto'
		}
	},
	root: {
		'& .MuiTable-root': {
			borderCollapse: 'inherit'
		}
	},
	sub: {
		fontSize: 'large'
	}
}))

const cellStyle = {
	whiteSpace: 'nowrap'
}

const FilterableTable = (props: any) => {
	const classes = useStyles()
	//06-24-2022, Matheus Augusto talked with Maxime Felder and was oriented to disable eslint sast rules for non-literal argument errors cause we need it to not be a non-literal argument, this one is a regexp error a ticket was open to refactor regexp rule.
	// eslint-disable-next-line
	const regex = new RegExp('^(.*)([\u2090-\u209C][\u2080-\u2089]+,?[^\u0000-\u00ff]+)+(.*)$')

	return (
		<div className={classes.root}>
			<MaterialTable
				title={props.title}
				style={{ tableLayout: 'fixed', width: '100%', height: '100%', borderCollapse: 'inherit' }}
				icons={tableIcons}
				data={props.data}
				onColumnDragged={props.onColumnDragged}
				columns={props.columns.map((column: any) => ({
					...column,
					align: 'center',
					cellStyle: cellStyle,
					disableClick: column.key === 'carbonSteelCertificate.downloadCertificateButton',
					title: regex.test(column.title) ? (
						<div>
							{column.title.match(regex)[1]}
							<sub className={classes.sub}>{column.title.match(regex)[2]}</sub>
							{column.title.match(regex)[3]}
						</div>
					) : (
						column.title
					)
				}))}
				options={{
				    draggable: props.draggable,
					initialPage: props.currentPageNumber ? props.currentPageNumber : 0,
					pageSize: props.currentPageSize,
					pageSizeOptions: [5, 10, 20, 30, 40, 50],
					emptyRowsWhenPaging: false,
					filtering: true,
					selection: true,
					search: true,
					showTextRowsSelected: false,
					headerStyle: {
						textAlign: 'center',
						whiteSpace: 'nowrap',
						fontWeight: 'bold'
					},
					maxBodyHeight: 'calc(100vh - 250px)',
					rowStyle: rowData => ({
						backgroundColor: rowData.tableData.checked ? '#C7E1BF80' : 'white'
					})
				}}
				onRowClick={(event, rowData: any) => {
					const rowDataCopy = rowData
					rowDataCopy.tableData.checked = !rowDataCopy.tableData.checked
					if (rowDataCopy.tableData.checked) {
						props.selectedData.push(rowDataCopy)
					}
					props.setSelectedRows(props.selectedData.filter((data: any) => data?.tableData?.checked))
				}}
				onChangePage={props.setCurrentPageNumber ? props.setCurrentPageNumber : () => {}}
				onChangeRowsPerPage={props.setCurrentPageSize}
				onSelectionChange={props.setSelectedRows}
				components={props.components || {}}
			/>
		</div>
	)
}
export default FilterableTable
