import { Box, Button, Card, Checkbox, Divider, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles, Theme, Typography } from '@material-ui/core';
import { ArrowDownward, ArrowUpward, ExploreOutlined } from '@material-ui/icons';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import requests from '../api/api';
import LoadingOverlay from '../components/common/LoadingOverlay';
import { AppContext } from '../stores/AppStore';
import { PlppContext } from '../stores/PlppStore';
import { UserContext } from '../stores/UserStore';

const useStyles = makeStyles((theme: Theme) => ({
  orderCard: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight: 'bold',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 2px 4px #03071C24',
    borderRadius: '4px',
    opacity: 1,
    padding: '16px',
    margin: '12px',
  },
  itemGrid: {
    backgroundColor: '#1A2981',
  },
  itemList: {
    overflow: 'auto',
    maxHeight: 'calc(100vh - 200px)',
  },
  exploreButton: {
    width: '30%',
    color: '#1A2981',
    margin: '12px',
    backgroundColor: 'white',
    padding: '0',
  },
  clearButton: {
    color: theme.palette.primary.main,
    margin: '12px',
    textTransform: 'none',
    padding: '8px 24px',
    "&:hover":{
        backgroundColor:'#EAFAFF'
    }
  },

  selectAllButton: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    margin: '12px',
    textTransform: 'none',
    padding: '8px 24px',
  },
  projectTitle: {
    // flexGrow: 1,
    color: '#1A2981',
    paddingLeft:'23px',
    margin: '32px 0px',
    display: 'flex',
  },
  materialTitle: {
    // flexGrow: 1,
    color: 'white',
    textAlign: 'left',
    font: 'normal normal 500 16px Roboto',
    letterSpacing: 0,
    opacity: 1,
  },
  selectedItem: {
    backgroundColor: '#EAFAFF',
  }
}));

const ProjectAndMaterialData = () => {
  const classes = useStyles();
  const { pageLoading, setPageLoading } = useContext(AppContext);
  const { plppState, plppDispatch } = useContext(PlppContext);
  const { setAuthState, currentUser } = useContext(UserContext);
  const [isSortedAscending, setSortedAscending] = useState(true);
  const { t } = useTranslation();
  const history = useHistory();

  // Init
  useEffect(() => {
    setPageLoading(true);
    plppDispatch({ type: 'set_is_need_to_refresh_plpp_data', isNeedToRefreshPlppData: true })
    requests
      .getOrderData(currentUser.id)
      .then((data) => {
        plppDispatch({
          type: 'set_all_orders',
          allOrders: data,
        });
      })
      .finally(() => {
        setPageLoading(false);
      })
      .catch((e) => {
        if (e.resultCode === 'error.expired.token') {
          setAuthState('LOGIN');
        } else {
          toast.error(e.message);
        }
      });
  }, [plppDispatch, setPageLoading, setAuthState]);

  // Order selection
  const selectOrder = (order: any) => {
    let selectedOrderIds = plppState.selectedOrderIds;

    if (selectedOrderIds.includes(order.id)) {
      selectedOrderIds = selectedOrderIds.filter(orderId => orderId !== order.id);
    } else {
      selectedOrderIds.push(order.id);
    }

    setSelectedOrderIds(selectedOrderIds);
  };

  const clearAllOrders = () => {
    setSelectedOrderIds([]);
  };

  const selectAllOrders = () => {
    setSelectedOrderIds(plppState.allOrders.map(order => order.id));
  };

  const setSelectedOrderIds = (selectedOrderIds: string[]) => {
    plppDispatch({
      type: 'init_selected_order_ids',
      selectedOrderIds,
    });
  }

  // Item selection
  const selectItem = (item: any) => {
    let selectedItemIds = plppState.selectedItemIds;

    if (selectedItemIds.includes(item.id)) {
      selectedItemIds = selectedItemIds.filter(itemId => itemId !== item.id);
    } else {
      selectedItemIds.push(item.id);
    }

    setSelectedItemIds(selectedItemIds);
  };

  const clearAllItems = () => {
    setSelectedItemIds([]);
  };

  const selectAllItems = () => {
    setSelectedItemIds(plppState.allItemsForSelectedOrders.map(item => item.id));
  };

  const setSelectedItemIds = (selectedItemIds: string[]) => {
    plppDispatch({
      type: 'init_selected_item_ids',
      selectedItemIds,
    });
  }

  const reverseSortItems = () => {
    setSortedAscending(!isSortedAscending);
    plppDispatch({
      type: 'reverse_all_items',
    });
  }

  const onExploreClick = (link) => () => {
    history.push(link);
  };

  return pageLoading ? (
    <LoadingOverlay open={true} />
  ) : (
    <Box width='100%' height='100%' padding={1.5}>
      <Grid container spacing={3}>

        <Grid item xs={8}>
          <Box mt={1} mb={2} style={{justifyContent: 'space-between', display: 'flex'}} >
            <Typography variant='h4' className={classes.projectTitle}>{t('project-plpp-data.project.title')}</Typography>
             <Box style={{alignSelf: 'center'}}>
              <Button
                className={classes.clearButton}
                onClick={clearAllOrders}
              >
                {t('ClearAll')}
              </Button>
              <Button
                className={classes.selectAllButton}
                onClick={selectAllOrders}
              >
                {t('SelectAll')}
              </Button>
            </Box>
          </Box>

          <Grid style={{padding: '0px 12px '}} container spacing={1}>
            {plppState.allOrders.map(order =>
              <Grid key={order.id} item xs={12} sm={4}>
                <Card onClick={() => selectOrder(order)} className={classes.orderCard}>
                  <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <Box>
                      <Box style={{color: '#1A2981'}}>{order.vallourecOrderReference}</Box>
                      <Box fontSize='16px'>{order.customerProjectName}</Box>
                    </Box>
                    <Checkbox
                      checked={plppState.selectedOrderIds.includes(order.id)}
                      color="primary"
                    />
                  </Box>
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>


        <Grid item xs={4} className={classes.itemGrid}>
          <Box mt={6} mb={2} style={{paddingTop: '60px'}}>
            <Typography className={classes.materialTitle}>{t('project-plpp-data.material.title')}</Typography>
          </Box>
          <Card style={{paddingBottom: '5%'}}>
            <Box display='flex' alignItems='center' padding={4}>
              <Box mr={2}>
                <Checkbox
                  id="selectAllItems"
                  edge="start"
                  checked={plppState.allItemsForSelectedOrders.length > 0 && plppState.selectedItemIds.length === plppState.allItemsForSelectedOrders.length}
                  color="primary"
                  onClick={() => plppState.selectedItemIds.length === plppState.allItemsForSelectedOrders.length ? clearAllItems() : selectAllItems()}
                />
              </Box>
              <Box ml={1} style={{textTransform: 'uppercase', fontWeight: 'bold'}}>
                {t('plpp-data.Material')}
                <IconButton size='small' style={{marginLeft: '10px'}} onClick={() => { reverseSortItems(); }} >
                  {isSortedAscending ? <ArrowDownward /> : <ArrowUpward />}
                </IconButton>
              </Box>
            </Box>
            <Divider />
            <List className={classes.itemList}>
              {plppState.allItemsForSelectedOrders.map(item =>
                <Box key={item.id} style={{paddingLeft: '16px'}}>
                  <ListItem role={undefined} dense button onClick={() => selectItem(item)} className={plppState.selectedItemIds.includes(item.id) ? classes.selectedItem : ''}>
                    <ListItemIcon>
                      <Checkbox edge="start" checked={plppState.selectedItemIds.includes(item.id)} color="primary" />
                    </ListItemIcon>
                    <ListItemText id={`checkbox-list-label-${item.id}`} primary={`${item.display}`} />
                  </ListItem>
                  <Divider />
                </Box>
              )}
            </List>
          </Card>
          <Box visibility={plppState.selectedItemIds.length > 0 ? "visible" : "hidden"} display='flex' justifyContent='center' style={{marginTop: '24px'}}>
            <Button
              color='inherit'
              variant='contained'
              className={classes.exploreButton}
              onClick={onExploreClick('/plpp-data')}
            >
               <IconButton
								color="inherit"
                style={{paddingLeft: '0px'}}
							>
								<ExploreOutlined />
							</IconButton> 
              {t('project-plpp-data.explore')}
            </Button>
          </Box>
        </Grid>

      </Grid>
    </Box >
  );
};

export default ProjectAndMaterialData;
