import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

const smartengoTheme = createMuiTheme({
   palette: {
      primary: { main: '#009EE0', dark:'#000000' },
      secondary: { main: '#6a5cff' },
      success: { main: '#59e480' },
      info: { main: '#59CBE4' },
      warning: { main: '#fbca4d' },
      error: { main: '#FB7171' },
      // fontSuccess: { main: '#1ebf4b' },
      // background: { main: '#c0c0c01a' },
      // header: { main: '#f8faff' },
      // black: { main: '#000000' },
      // darkGray: { main: '#303030' },
//       gray: { main: '#777777' },
      // metalic: { main: '#919098' },
//       lightGray: { main: '#e0e0e0' },
      // whitesmoke: { main: '#f1f1f1' },
      // offWhite: { main: '#f5f5f5' },
      // white: { main: '#ffffff }'
   }, 
   shadows: [
      'none',
      '0 3px 6px 0 rgb(0 0 0 / 5%)',
      '0 3px 6px 0 rgb(0 0 0 / 5%)',
      '0 3px 6px 0 rgb(0 0 0 / 5%)',
      '0 3px 6px 0 rgb(0 0 0 / 5%)',
      '0 3px 6px 0 rgb(0 0 0 / 5%)',
      '0 3px 6px 0 rgb(0 0 0 / 5%)',
      '0 3px 6px 0 rgb(0 0 0 / 5%)',
      '0 3px 6px 0 rgb(0 0 0 / 5%)',
      '0 3px 6px 0 rgb(0 0 0 / 5%)',
      '0 3px 6px 0 rgb(0 0 0 / 5%)',
      '0 3px 6px 0 rgb(0 0 0 / 5%)',
      '0 3px 6px 0 rgb(0 0 0 / 5%)',
      '0 3px 6px 0 rgb(0 0 0 / 5%)',
      '0 3px 6px 0 rgb(0 0 0 / 5%)',
      '0 3px 6px 0 rgb(0 0 0 / 5%)',
      '0 3px 6px 0 rgb(0 0 0 / 5%)',
      '0 3px 6px 0 rgb(0 0 0 / 5%)',
      '0 3px 6px 0 rgb(0 0 0 / 5%)',
      '0 3px 6px 0 rgb(0 0 0 / 5%)',
      '0 3px 6px 0 rgb(0 0 0 / 5%)',
      '0 3px 6px 0 rgb(0 0 0 / 5%)',
      '0 3px 6px 0 rgb(0 0 0 / 5%)',
      '0 3px 6px 0 rgb(0 0 0 / 5%)',
      '0 3px 6px 0 rgb(0 0 0 / 5%)',
   ],
   shape: { borderRadius: 6 }
});

export default responsiveFontSizes(smartengoTheme);
