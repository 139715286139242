/** @format */

import { createStyles, Icon, makeStyles, Theme } from '@material-ui/core'
import TableRow from '@material-ui/core/TableRow'
import { MTableHeader } from 'material-table'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import expandLeft from '../../assets/icons/expand_left.svg'
import expandRight from '../../assets/icons/expand_right.svg'
import ChipsFilters from '../../components/common/ChipsFilters'
import FilterableTable from '../../components/common/FilterableTable'
import LoadingOverlay from '../../components/common/LoadingOverlay'
import { AppContext } from '../../stores/AppStore'
import { PlppColumn, PlppContext } from '../../stores/PlppStore'
import { sortByPosition } from '../../utils/sort-utils'
import PopoverFilter from './PopoverFilter'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		groupHeader: {
			padding: '4px',
			fontSize: '1rem',
			borderTop: 'solid 1px lightgrey',
			borderLeft: 'solid 1px lightgrey',
			borderRight: 'solid 1px lightgrey',
			minWidth: '400px'
		},
		backgroundExtended: {
			background: '#F2FAFD',
			backgroundClip: 'padding-box'
		},
		noBackground: {
			background: '#FFFFFF',
			backgroundClip: 'padding-box'
		},
		vertical_align: {
			verticalAlign: 'middle'
		},
		expand_btn: {
			cursor: 'pointer',
			float: 'right'
		},
		collapse_btn: {
			cursor: 'pointer',
			float: 'left'
		},
		grey: {
			filter: 'invert(67%) sepia(57%) saturate(0%) hue-rotate(189deg) brightness(103%) contrast(109%)'
		},
		green: {
			filter: 'invert(53%) sepia(19%) saturate(1266%) hue-rotate(100deg) brightness(96%) contrast(87%)'
		},
		red: {
			filter: 'invert(41%) sepia(62%) saturate(804%) hue-rotate(316deg) brightness(96%) contrast(97%)'
		}
	})
)

const TestsPlppDataTable = (props: { switchToPipeView: () => void }) => {
	const classes = useStyles()
	const { pageLoading } = useContext(AppContext)
	const { pageLoadingFomDrawer } = useContext(AppContext)
	const { plppState, plppDispatch } = useContext(PlppContext)
	const { t } = useTranslation()

	const [isChemicalGroupExpanded, setIsChemicalGroupExpanded] = useState<boolean>(false)
	const [isTensileTestGroupExpanded, setIsTensileTestGroupExpanded] = useState<boolean>(false)
	const [isImpactTestGroupExpanded, setIsImpactTestGroupExpanded] = useState<boolean>(false)
	const [isHardnessTestGroupExpanded, setIsHardnessTestGroupExpanded] = useState<boolean>(false)

	const [chemicalAnalysisColumns, setChemicalAnalysisColumns] = useState<string[]>([])
	const [tensileTestColumns, setTensileTestColumns] = useState<string[]>([])
	const [impactTestColumns, setImpactTestColumns] = useState<string[]>([])
	const [hardnessTestColumns, setHardnessTestColumns] = useState<string[]>([])

	const [emptyColumns, setEmptyColumns] = useState<PlppColumn[]>([])
	const [groupsOrder, setGroupsOrder] = useState<string[]>([])

	/**
	 *
	 * @param predicate A predicate to filter the list content
	 * @returns The list of columns matching the predicate
	 */
	const retrieveAllColumnsAccordingToPredicate = useCallback(
		(predicate: (column: PlppColumn) => boolean): string[] => {
			return plppState.plppColumns
				.filter(
					column =>
						column.visible &&
						column.isForTestView &&
						((!plppState.showEmptyColumns && !emptyColumns.includes(column)) || plppState.showEmptyColumns)
				)
				.filter(column => predicate(column))
				.sort((a, b) => sortByPosition(a, b))
				.map(key => key.field)
		},
		[
			plppState.plppColumns, // refresh the callback when plppColumns changes
			plppState.showEmptyColumns, // refresh the callback when showEmptyColumns changes /!\ only emptyColumns isn't enough
			emptyColumns, // refresh the callback when emptyColumns changes /!\ only showEmptyColumns isn't enough
			plppState.selectedTab, // refresh the callback when selectedTab changes
			plppState.isNeedToRefreshPlppColumns // refresh the callback when needRefreshPlppColumns
		]
	)

	/**
	 * Retrieve the full list of chemical analysis columns
	 */
	const retrieveAllChemicalAnalysisColumns = useCallback((): string[] => {
		return retrieveAllColumnsAccordingToPredicate(column => column.chemicalAnalysisGroup)
	}, [plppState.plppColumns, plppState.showEmptyColumns, emptyColumns, plppState.selectedTab, plppState.isNeedToRefreshPlppColumns])

	/**
	 * Retrieve the full list of impact test columns
	 */
	const retrieveAllImpactTestColumns = useCallback((): string[] => {
		return retrieveAllColumnsAccordingToPredicate(column => column.impactTestGroup)
	}, [plppState.plppColumns, plppState.showEmptyColumns, emptyColumns, plppState.selectedTab, plppState.isNeedToRefreshPlppColumns])

	/**
	 * Retrieve the full list of tensile test columns
	 */
	const retrieveAllTensileTestColumns = useCallback((): string[] => {
		return retrieveAllColumnsAccordingToPredicate(column => column.tensileTestGroup)
	}, [plppState.plppColumns, plppState.showEmptyColumns, emptyColumns, plppState.selectedTab, plppState.isNeedToRefreshPlppColumns])

	/**
	 * Retrieve the full list of hardness test columns
	 */
	const retrieveAllHardnessTestColumns = useCallback((): string[] => {
		return retrieveAllColumnsAccordingToPredicate(column => column.hardnessTestGroup)
	}, [plppState.plppColumns, plppState.showEmptyColumns, emptyColumns, plppState.selectedTab, plppState.isNeedToRefreshPlppColumns])

	/**
	 *
	 * @param allColumns All columns of wanted group
	 * @param isGroupExpdanded A boolean to defined whether wanted group is expanded or not
	 * @returns The columns to display : either the 3 first ones if group is collapsed or the full list of columns ig group is expanded
	 */
	const retrieveGroupColumns = (allColumns: string[], isGroupExpdanded: boolean): string[] => {
		return !isGroupExpdanded && allColumns.length > 3 ? allColumns.slice(0, 3) : allColumns
	}

	/**
	 * Retrieve chemical analysis columns to display
	 */
	const retrieveChemicalAnalysisColumns = useCallback(() => {
		setChemicalAnalysisColumns(retrieveGroupColumns(retrieveAllChemicalAnalysisColumns(), isChemicalGroupExpanded))
	}, [retrieveAllChemicalAnalysisColumns, isChemicalGroupExpanded])

	useEffect(() => {
		retrieveChemicalAnalysisColumns()
	}, [
		isChemicalGroupExpanded, // Retreive chemical composition columns when the state of isChemicalGroupExpanded changes
		plppState.showEmptyColumns, // Retrieve chemical composition columns when user changes the Hide/Show empty columns state
		plppState.selectedTab, // refresh the callback when selectedTab changes
		plppState.isNeedToRefreshPlppColumns // refresh the callback when needRefreshPlppColumns
	    ])

	/**
	 * Retrieve tensile test columns to display
	 */
	const retrieveTensileTestColumns = useCallback(() => {
		setTensileTestColumns(retrieveGroupColumns(retrieveAllTensileTestColumns(), isTensileTestGroupExpanded))
	}, [retrieveAllTensileTestColumns, isTensileTestGroupExpanded])

	useEffect(() => {
		retrieveTensileTestColumns()
	}, [
		isTensileTestGroupExpanded, // Retreive chemical composition columns when the state of isTensileTestGroupExpanded changes
		plppState.showEmptyColumns, // Rerieve chemical composition columns when user changes the Hide/Show empty columns state
		plppState.selectedTab, // refresh the callback when selectedTab changes
		plppState.isNeedToRefreshPlppColumns // refresh the callback when needRefreshPlppColumns
	])

	/**
	 * Retrieve impact test columns to display
	 */
	const retrieveImpactTestColumns = useCallback(() => {
		setImpactTestColumns(retrieveGroupColumns(retrieveAllImpactTestColumns(), isImpactTestGroupExpanded))
	}, [retrieveAllImpactTestColumns, isImpactTestGroupExpanded])

	useEffect(() => {
		retrieveImpactTestColumns()
	}, [
		isImpactTestGroupExpanded, // Retreive chemical composition columns when the state of isImpactTestGroupExpanded changes
		plppState.showEmptyColumns, // Rerieve chemical composition columns when user changes the Hide/Show empty columns state
		plppState.selectedTab, // refresh the callback when selectedTab changes
		plppState.isNeedToRefreshPlppColumns // refresh the callback when needRefreshPlppColumns
	])

	/**
	 * Retrieve hardness test columns to display
	 */
	const retrieveHardnessTestColumns = useCallback(() => {
		setHardnessTestColumns(retrieveGroupColumns(retrieveAllHardnessTestColumns(), isHardnessTestGroupExpanded))
	}, [retrieveAllHardnessTestColumns, isHardnessTestGroupExpanded])

	useEffect(() => {
		retrieveHardnessTestColumns()
	}, [
		isHardnessTestGroupExpanded, // Retreive chemical composition columns when the state of isHardnessTestGroupExpanded changes
		plppState.showEmptyColumns, // Rerieve chemical composition columns when user changes the Hide/Show empty columns state
		plppState.selectedTab, // refresh the callback when selectedTab changes
		plppState.isNeedToRefreshPlppColumns // refresh the callback when needRefreshPlppColumns
	])

	function updateGroupsOrder() {
		setGroupsOrder(
			plppState.plppColumns
				.filter(
					column =>
						column.visible &&
						column.isForTestView &&
						((!plppState.showEmptyColumns && !emptyColumns.includes(column)) || plppState.showEmptyColumns)
				)
				.filter(
					column =>
						column.chemicalAnalysisGroup ||
						column.hardnessTestGroup ||
						column.tensileTestGroup ||
						column.impactTestGroup
				)
				.sort((a, b) => sortByPosition(a, b))
				.map(column => column.key.split('.')[1])
				.filter((value, index, self) => {
					return self.indexOf(value) === index
				})
		)
	}

	useEffect(() => {
		updateGroupsOrder()
	}, [
		emptyColumns,
		plppState.showEmptyColumns, // Update groups if users clicks on Hide/Show empty columns
		plppState.plppColumns,
		plppState.isNeedToRefreshPlppColumns,
		plppState.columnsOrderHasChanged
	])

	useEffect(() => {
		setEmptyColumns(
			plppState.plppColumns.filter(column => {
				return (
					plppState.displayedTestsPlppData.filter(data => {
						const keys = column.key.split('.')
						let value = data
						keys.forEach(function (key) {
							if (value != null) {
								value = value[key]
							}
						})
						return value !== null && value !== undefined && value !== ''
					}).length === 0
				)
			})
		)
	}, [plppState.displayedTestsPlppData, plppState.plppColumns, plppState.selectedTab, plppState.isNeedToRefreshPlppColumns])

	useEffect(() => {
		retrieveChemicalAnalysisColumns()
		retrieveTensileTestColumns()
		retrieveImpactTestColumns()
		retrieveHardnessTestColumns()
	}, [
		plppState.plppColumns,
		groupsOrder,
		emptyColumns,
		plppState.hasClickOnViewTestsFromSelection,
		plppState.isNeedToRefreshPlppColumns
	])

	useEffect(() => {}, [pageLoading, pageLoadingFomDrawer])

	const addBackgroundHeaderColorAndBorder = (isGroupExpanded, start, end) => {
		const array = document.getElementsByTagName('tr')[1]
			? Array.from(
					document.getElementsByTagName('tr')[1] &&
						document.getElementsByTagName('tr')[1].getElementsByTagName('th')
			  )
			: []
		array.forEach((element, index) => {
			if (index >= start && index <= end) {
				element.style.background = isGroupExpanded ? '#F2FAFD' : '#FFFFFF'
				element.style.borderLeft = index === start ? 'solid 1px lightgrey' : 'none'
				element.style.borderRight = index === end ? 'solid 1px lightgrey' : 'none'
			}
		})
	}

	/**
	 * @param cell The cell to search text in
	 * @param textContent  The text content to search in the cell
	 * @returns Whether given cell contains given text content
	 */
	function cellContains(cell: { textContent: string }, textContent: string) {
		return cell.textContent.toLowerCase().includes(textContent)
	}

	let dragSrcEl = null
	const handleDragStart = e => {
		dragSrcEl = e.target
	}
	const handleDragEnd = () => {}
	const handleDragOver = e => {
		e.preventDefault()
		return false
	}
	const handleDragEnter = () => {}
	const handleDragLeave = () => {}
	const handleDrop = e => {
		e.stopPropagation() // stops the browser from redirecting.
		e.stopImmediatePropagation() // avoid drop to be called multiple times

		let target = e.target
		while (target.nodeName !== 'TH') {
			// Assure us to select th element (not span inside it)
			target = target.parentElement
		}
		if (target === null) {
			return false
		}

		const getFirstIndex = columnToSearch => {
			let firstColumn: PlppColumn
			if (targetContains('chemical analysis')) {
				firstColumn = findFirstChemicalAnalysisColumn()
			} else if (targetContains('impact test')) {
				firstColumn = findFirstImpactTestColumn()
			} else if (targetContains('hardness test')) {
				firstColumn = findFirstHardnessTestColumn()
			} else if (targetContains('tensile test')) {
				firstColumn = findFirstTensileTestColumn()
			} else {
				firstColumn = findColumn(columnToSearch)
				if (firstColumn.chemicalAnalysisGroup) {
					// Target is a column inside chemical analysis group
					// we search for the first element of the group
					firstColumn = findFirstChemicalAnalysisColumn()
				} else if (firstColumn.tensileTestGroup) {
					// Target is a column inside tensile test group
					// we search for the first element of the group
					firstColumn = findFirstTensileTestColumn()
				} else if (firstColumn.impactTestGroup) {
					// Target is a column inside impact test group
					// we search for the first element of the group
					firstColumn = findFirstImpactTestColumn()
				} else if (firstColumn.hardnessTestGroup) {
					// Target is a column inside hardness tests group
					// we search for the first element of the group
					firstColumn = findFirstHardnessTestColumn()
				}
			}
			return plppState.plppColumns.find(element => element === firstColumn).position

			/**
			 * @param textContent The text content of the target
			 * @returns Whether the target's text content contains given text content
			 */
			function targetContains(textContent: string): boolean {
				return cellContains(columnToSearch, textContent)
			}

			/**
			 * @param predicate The predicate to evaluate
			 * @returns The first column matching the given predicate
			 */
			function findFirstWithPredicate(predicate: Function): PlppColumn {
				return plppState.plppColumns.find(element => predicate(element) && element.visible)
			}

			/**
			 * @returns The first tensile test column
			 */
			function findFirstTensileTestColumn(): PlppColumn {
				return findFirstWithPredicate(element => element.tensileTestGroup)
			}

			/**
			 * @returns The first hardness test column
			 */
			function findFirstHardnessTestColumn(): PlppColumn {
				return findFirstWithPredicate(element => element.hardnessTestGroup)
			}

			/**
			 * @returns The first impact test column
			 */
			function findFirstImpactTestColumn(): PlppColumn {
				return findFirstWithPredicate(element => element.impactTestGroup)
			}

			/**
			 * @returns The first chemical analysis column
			 */
			function findFirstChemicalAnalysisColumn(): PlppColumn {
				return findFirstWithPredicate(element => element.chemicalAnalysisGroup)
			}
		}

		let columnsToMove: PlppColumn[] = getAllColumnsToMove()

		let indexSource: number
		let indexTarget: number

		// If sourceColumn belongs to a group ...
		if (sourceColumnBelongsToAGroup()) {
			const sourceColumn = findColumn(dragSrcEl)
			const targetColumn = findColumn(target)

			// ... and has been dropped on a column of same group
			if (
				sourceColumn !== undefined &&
				targetColumn !== undefined &&
				((true === sourceColumn.chemicalAnalysisGroup) === targetColumn.chemicalAnalysisGroup ||
					(true === sourceColumn.hardnessTestGroup) === targetColumn.hardnessTestGroup ||
					(true === sourceColumn.impactTestGroup) === targetColumn.impactTestGroup ||
					(true === sourceColumn.tensileTestGroup) === targetColumn.tensileTestGroup)
			) {
				// sourceColumn belongs to a group and has been dropped on same group :
				// we don't want to move the full group anymore
				// but only the single column
				columnsToMove = [sourceColumn]

				// The indexSource is the index of the single source column, no more the index of the first column of the group
				indexSource = sourceColumn.position

				// The indexTarget is the index of the single target column, no more the index of the first column of the group
				indexTarget = targetColumn.position
			} else {
				// indexSource is the index of the first column of the source group
				indexSource = getFirstIndex(dragSrcEl)

				// indexTarget is the index of the first column of the target group, or the index of single target column
				indexTarget = getFirstIndex(target)
			}
		} else {
			indexSource = getFirstIndex(dragSrcEl)
			indexTarget = getFirstIndex(target)
		}

		// If user has not dropped the column on itself
		if (columnHasNotBeenDroppedOnItself()) {
			// Move the columns
			moveColumns()
		}

		return false

		/**
		 
		 * @returns true if columnsToMove has more than 1 element, false otherwise
		 */
		function sourceColumnBelongsToAGroup(): boolean {
			return columnsToMove.length > 1
		}

		/**
		 * Dispatch an event to move columns
		 */
		function moveColumns() {
			plppDispatch({
				type: 'move_columns',
				sourceIndex: indexSource,
				targetIndex: indexTarget,
				columnsToMove
			})
		}

		/**
		 * @returns Whether the column has been dropped on itself or not
		 * false if indexSource equals indexTarget, true otherwise
		 */
		function columnHasNotBeenDroppedOnItself(): boolean {
			return indexSource !== indexTarget
		}

		/**
		 * @returns The list of columns to move
		 */
		function getAllColumnsToMove(): PlppColumn[] {
			let groupColumns: PlppColumn[] = []
			if (sourceContains('chemical analysis')) {
				groupColumns = findChemicalAnalysisColumns()
			} else if (sourceContains('impact test')) {
				groupColumns = findImpactTestColumns()
			} else if (sourceContains('hardness test')) {
				groupColumns = findHardnessTestColumns()
			} else if (sourceContains('tensile test')) {
				groupColumns = findTensileTestColumns()
			} else {
				let sourceColumn: PlppColumn = findColumn(dragSrcEl)

				if (sourceColumn.chemicalAnalysisGroup) {
					// Source is a column inside chemical analysis group, we have to move the whole group
					groupColumns = findChemicalAnalysisColumns()
				} else if (sourceColumn.tensileTestGroup) {
					// Source is a column inside tensile test group, we have to move the whole group
					groupColumns = findTensileTestColumns()
				} else if (sourceColumn.impactTestGroup) {
					// Source is a column inside impact test group, we have to move the whole group
					groupColumns = findImpactTestColumns()
				} else if (sourceColumn.hardnessTestGroup) {
					// Source is a column inside hardness test group, we have to move the whole group
					groupColumns = findHardnessTestColumns()
				}

				// If columns does not belong to a group
				if (groupColumns.length === 0) {
					// Add the single column
					groupColumns.push(sourceColumn)
				}
			}

			return groupColumns
		}

		/**
		 * @param col The column to search
		 * @returns The corresponding columns from plppColumns
		 */
		function findColumn(col: any): PlppColumn {
			return plppState.plppColumns.find((element) => {
			    // th > span > span#id
			    // can't use innerHTML.includes because we need to check exactly if the key corresponds
				return col.childNodes[0].childNodes[0].id === element.key && element.isForTestView // find the column containing the plppColumns key
			})
		}

		/**
		 * @param textContent The text content of the source
		 * @returns Whether the source's text content contains given text content
		 */
		function sourceContains(textContent: string): boolean {
			return cellContains(dragSrcEl, textContent)
		}

		/**
		 * @param predicate The predicate to evaluate
		 * @returns All columns matching given predicate
		 */
		function findColumnsWithPredicate(predicate: Function): PlppColumn[] {
			return plppState.plppColumns.filter(
				column =>
					column.visible &&
					column.isForTestView &&
					((!plppState.showEmptyColumns && !emptyColumns.includes(column)) || plppState.showEmptyColumns) &&
					predicate(column)
			)
		}

		/**
		 * @returns All columns related to hardness tests
		 */
		function findHardnessTestColumns(): PlppColumn[] {
			return findColumnsWithPredicate(column => column.hardnessTestGroup)
		}

		/**
		 * @returns all columns related to impact tests
		 */
		function findImpactTestColumns(): PlppColumn[] {
			return findColumnsWithPredicate(column => column.impactTestGroup)
		}

		/**
		 * @returns All columns related to tensile tests
		 */
		function findTensileTestColumns(): PlppColumn[] {
			return findColumnsWithPredicate(column => column.tensileTestGroup)
		}

		/**
		 * @returns All columns related to chemical analysis
		 */
		function findChemicalAnalysisColumns(): PlppColumn[] {
			return findColumnsWithPredicate(column => column.chemicalAnalysisGroup)
		}
	}

	const setExpandHeader = (columns: string[], isGroupExpanded: boolean) => {
		setTimeout(() => {
			let startGroup = -1
			let endGroup = -1

			// CSS to stick header and filter
			const array = Array.from(document.getElementsByClassName('MuiTableRow-root'))
			array.forEach((lineElement, lineIndex) => {
				const isHeaderElement = lineElement.getElementsByTagName('th').length > 0
				const isFilterElement = containsHeaderWithExpand ? lineIndex === 2 : lineIndex === 1
				const rowElements = isHeaderElement
					? lineElement.getElementsByTagName('th')
					: lineElement.getElementsByTagName('td')

				if (isHeaderElement || isFilterElement) {
					Array.from(rowElements).forEach((element, columnIndex) => {
						if (isHeaderElement || isFilterElement) {
							element.style.position = 'sticky'
							element.style.top =
								lineIndex === 0
									? '0px'
									: lineIndex === 1
									? (array[0] as HTMLElement).offsetHeight + 'px'
									: (array[0] as HTMLElement).offsetHeight + (array[1] as HTMLElement).offsetHeight + 'px'
							if (isFilterElement) {
								element.style.background = '#FFFFFF'
							}
							element.style.zIndex = columnIndex === 1 ? '999' : '998'
						}
						if (isHeaderElement) {
							if (element.textContent === t('plpp-data.' + columns[0] + '.name')) {
								startGroup = columnIndex
							}
							if (element.textContent === t('plpp-data.' + columns[columns.length - 1] + '.name')) {
								endGroup = columnIndex
							}
							if (element.textContent !== '' && columnIndex !== 1) {
								element.draggable = true
								element.addEventListener('dragstart', handleDragStart)
								element.addEventListener('dragover', handleDragOver)
								element.addEventListener('dragenter', handleDragEnter)
								element.addEventListener('dragleave', handleDragLeave)
								element.addEventListener('dragend', handleDragEnd)
								element.addEventListener('drop', handleDrop)
							}
						}
					})
				}

				// keep heat columns sticky de left on scroll to right
				const heatElement = rowElements[1]
				if (heatElement) {
					heatElement.style.position = 'sticky'
					heatElement.style.left = '0'
					heatElement.style.zIndex = isHeaderElement || isFilterElement ? '999' : '998'
					heatElement.style.backgroundColor =
						heatElement.parentElement.style.backgroundColor === 'rgba(199, 225, 191, 0.5)'
							? 'rgb(227,240,223)'
							: '#orange'
					heatElement.style.transition = 'all 300ms ease 0s'
				}
			})

			if (startGroup !== -1 && endGroup !== -1) {
				addBackgroundHeaderColorAndBorder(isGroupExpanded, startGroup, endGroup)
			}
		}, 1)
	}

	/**
	 * Set expand header above chemical analysis columns when chemical analysis columns change
	 */
	useEffect(() => {
		// Stick header and put right column and border for expands
		setExpandHeader(chemicalAnalysisColumns, isChemicalGroupExpanded)
	}, [chemicalAnalysisColumns])

	/**
	 * Set expand header above tensile test columns when tensile test columns change
	 */
	useEffect(() => {
		// Stick header and put right column and border for expands
		setExpandHeader(tensileTestColumns, isTensileTestGroupExpanded)
	}, [tensileTestColumns])

	/**
	 * Set expand header above impact test columns when tensile test columns change
	 */
	useEffect(() => {
		// Stick header and put right column and border for expands
		setExpandHeader(impactTestColumns, isImpactTestGroupExpanded)
	}, [impactTestColumns])

	/**
	 * Set expand header above hardness test columns when hardness test columns change
	 */
	useEffect(() => {
		// Stick header and put right column and border for expands
		setExpandHeader(hardnessTestColumns, isHardnessTestGroupExpanded)
	}, [hardnessTestColumns])

	const getColumnsToDisplay = () => {
		return plppState.plppColumns
			.filter(column => {
				return (
					(column.chemicalAnalysisGroup === undefined &&
						column.tensileTestGroup === undefined &&
						column.impactTestGroup === undefined &&
						column.hardnessTestGroup === undefined) ||
					(column.chemicalAnalysisGroup && chemicalAnalysisColumns.indexOf(column.field) > -1) ||
					(column.tensileTestGroup && tensileTestColumns.indexOf(column.field) > -1) ||
					(column.impactTestGroup && impactTestColumns.indexOf(column.field) > -1) ||
					(column.hardnessTestGroup && hardnessTestColumns.indexOf(column.field) > -1)
				)
			})
			.filter(column => {
				return (
					column.isMandatoryTestView ||
					(column.visible &&
						column.isForTestView &&
						((!plppState.showEmptyColumns && !emptyColumns.includes(column)) || plppState.showEmptyColumns))
				)
			})
			.sort((a, b) => sortByPosition(a, b))
			.map(col => {
				return {
					...col,
					filterComponent: props => (
						<PopoverFilter
							{...props}
							isPipeView={false}
							isAlphanumeric={t('plpp-data.' + col.key + '.filter') === 'alphanumeric'}
							onFilterChanged={onFilterChange(col.key)}
							columnTitle={col.key}
						/>
					),
                    title: <span id={col.field}>{t('plpp-data.' + col.key + '.name')}</span>,
				}
			})
	}

	const columnsToDisplay = useMemo(
		() => getColumnsToDisplay(),
		[
			groupsOrder,
			plppState.plppColumns,
			plppState.columnsOrderHasChanged,
			plppState.isNeedToRefreshPlppColumns,
			plppState.selectedTemplateId,
			chemicalAnalysisColumns, // Refresh columns to display if chemical analysis columns changes (after users clicks on collapse/expand or Hide/Show empty columns for ex)
			impactTestColumns, // Refresh columns to display if impact tests columns changes (after users clicks on collapse/expand or Hide/Show empty columns for ex)
			tensileTestColumns, // Refresh columns to display if tensile tests columns changes (after users clicks on collapse/expand or Hide/Show empty columns for ex)
			hardnessTestColumns // Refresh columns to display if hardness tests columns changes (after users clicks on collapse/expand or Hide/Show empty columns for ex)
		]
	)

	const onFilterChange = (key: string) => {
		return (columnId: any, paramFilter: any) => {
			const min = paramFilter.min ? paramFilter.min : ''
			const max = paramFilter.max ? paramFilter.max : ''
			const splitValues = paramFilter.value ? paramFilter.value.split('|') : ''

			if (paramFilter.clear || (min === '' && max === '' && splitValues === '')) {
				plppDispatch({
					type: 'remove_filter_from_columns_map',
					filterToRemoveFromPipeView: undefined,
					filterToRemoveFromTestView: key
				})
			} else {
				// construct the filtered map column
				let filteredTestsColumnsMap = new Map()
				filteredTestsColumnsMap.set(key, {
					values: splitValues,
					min: min,
					max: max
				})

				plppDispatch({
					type: 'set_filtered_columns_map',
					filteredColumnsMap: new Map(),
					filteredTestsColumnsMap
				})
			}
		}
	}

	/**
	 * Put group header above column header
	 */
	function putGroupHeaderAboveColumnHeader() {
		// Put expand/collapse header before column name header
		if (
			document.getElementsByTagName('thead') &&
			document.getElementsByTagName('thead')[0] &&
			document.getElementById('headerExpand')
		) {
			document.getElementsByTagName('thead')[0].prepend(document.getElementById('headerExpand'))
		}
	}

	/**
	 * Set group header above columns header when columns changes
	 */
	useEffect(() => {
		// Put expand/collapse header before column name header
		putGroupHeaderAboveColumnHeader()
	}, [
		columnsToDisplay, // refresh when columnsToDisplay changes
		plppState
	])

	const getDataToDisplay = () => {
		return plppState.displayedTestsPlppData
	}
	const dataToDisplay = useMemo(() => getDataToDisplay(), [plppState.displayedTestsPlppData])

	const setSelectedRows = (selectedPlppData: any[]) => {
		plppDispatch({ type: 'set_selected_tests_plpp_data', selectedTestsPlppData: selectedPlppData })
	}

	const getDeltaNumberOfColumns = (group1, group2) => {
		let group1Length = 0
		switch (group1) {
			case 'chemicalAnalysis':
				group1Length = chemicalAnalysisColumns.length
				break

			case 'impactTest':
				group1Length = impactTestColumns.length
				break

			case 'hardnessTest':
				group1Length = hardnessTestColumns.length
				break

			case 'tensileTest':
				group1Length = tensileTestColumns.length
				break
		}
		const findElementCondition = (element, group) => {
			switch (group) {
				case 'chemicalAnalysis':
					return (
						element.key.split('.')[1].toLowerCase().includes(group.toString().toLowerCase()) &&
						element.chemicalAnalysisGroup
					)
				case 'impactTest':
					return (
						element.key.split('.')[1].toLowerCase().includes(group.toString().toLowerCase()) &&
						element.impactTestGroup
					)
				case 'hardnessTest':
					return (
						element.key.split('.')[1].toLowerCase().includes(group.toString().toLowerCase()) &&
						element.hardnessTestGroup
					)
				case 'tensileTest':
					return (
						element.key.split('.')[1].toLowerCase().includes(group.toString().toLowerCase()) &&
						element.tensileTestGroup
					)
			}
		}
		const index1 =
			group1 != null
				? columnsToDisplay.findIndex(element => findElementCondition(element, group1)) + group1Length
				: 0
		const index2 =
			group2 != null
				? columnsToDisplay.findIndex(element => findElementCondition(element, group2))
				: columnsToDisplay.length

		return Math.abs(index2 - index1)
	}

	const isContainingHeaderWithExpand = () => {
		return (
			chemicalAnalysisColumns.length > 0 ||
			tensileTestColumns.length > 0 ||
			impactTestColumns.length > 0 ||
			hardnessTestColumns.length > 0
		)
	}

	const containsHeaderWithExpand = useMemo(
		() => isContainingHeaderWithExpand(),
		[chemicalAnalysisColumns, tensileTestColumns, impactTestColumns, hardnessTestColumns]
	)

	const RenderHeaderBetweenGroups = (group, index) => {
		const delta = getDeltaNumberOfColumns(groupsOrder[index], groupsOrder[index + 1])
		let returnedHtml = []
		if (groupsOrder[index + 1] !== null && delta > 0) {
			for (let i = 0; i < delta; i++) {
				returnedHtml.push(<th className={classes.noBackground}></th>)
			}
			return returnedHtml
		}
	}

	const RenderGroups = group => {
		switch (group) {
			case 'chemicalAnalysis':
				if (chemicalAnalysisColumns.length > 0) {
					return (
						<th
							colSpan={chemicalAnalysisColumns.length}
							className={[
								classes.groupHeader,
								isChemicalGroupExpanded ? classes.backgroundExtended : classes.noBackground
							].join(' ')}
						>
							<span
								onClick={() => setIsChemicalGroupExpanded(false)}
								className={[classes.collapse_btn, isChemicalGroupExpanded ? classes.red : classes.grey].join(
									' '
								)}
							>
								<Icon fontSize={'large'} className={classes.vertical_align}>
									<img src={expandLeft} height={36} alt="Logo" />
								</Icon>
								<span className={classes.vertical_align}>{t('Collapse')}</span>
							</span>
							<span color="grey">
								<span>
									{t('Chemical Analysis') + ' [' + retrieveAllChemicalAnalysisColumns().length + '] '}
								</span>
							</span>
							<span
								onClick={() => setIsChemicalGroupExpanded(true)}
								className={[classes.expand_btn, isChemicalGroupExpanded ? classes.grey : classes.green].join(
									' '
								)}
							>
								<span className={classes.vertical_align}>{t('Extend')}</span>
								<Icon fontSize={'large'} className={classes.vertical_align}>
									<img src={expandRight} height={36} alt="Logo" />
								</Icon>
							</span>
						</th>
					)
				}
				break
			case 'impactTest':
				if (impactTestColumns.length > 0) {
					return (
						<th
							colSpan={impactTestColumns.length}
							className={[
								classes.groupHeader,
								isImpactTestGroupExpanded ? classes.backgroundExtended : classes.noBackground
							].join(' ')}
						>
							<span
								onClick={() => setIsImpactTestGroupExpanded(false)}
								className={[classes.collapse_btn, isImpactTestGroupExpanded ? classes.red : classes.grey].join(
									' '
								)}
							>
								<Icon fontSize={'large'} className={classes.vertical_align}>
									<img src={expandLeft} height={36} alt="Logo" />
								</Icon>
								<span className={classes.vertical_align}>{t('Collapse')}</span>
							</span>
							<span color="grey">
								<span>
									{t('plpp-data.manageDataColumns.columns.impact_test') +
										' [' +
										retrieveAllImpactTestColumns().length +
										'] '}
								</span>
							</span>
							<span
								onClick={() => setIsImpactTestGroupExpanded(true)}
								className={[classes.expand_btn, isImpactTestGroupExpanded ? classes.grey : classes.green].join(
									' '
								)}
							>
								<span className={classes.vertical_align}>{t('Extend')}</span>
								<Icon fontSize={'large'} className={classes.vertical_align}>
									<img src={expandRight} height={36} alt="Logo" />
								</Icon>
							</span>
						</th>
					)
				}
				break
			case 'tensileTest':
				if (tensileTestColumns.length > 0) {
					return (
						<th
							colSpan={tensileTestColumns.length}
							className={[
								classes.groupHeader,
								isTensileTestGroupExpanded ? classes.backgroundExtended : classes.noBackground
							].join(' ')}
						>
							<span
								onClick={() => setIsTensileTestGroupExpanded(false)}
								className={[classes.collapse_btn, isTensileTestGroupExpanded ? classes.red : classes.grey].join(
									' '
								)}
							>
								<Icon fontSize={'large'} className={classes.vertical_align}>
									<img src={expandLeft} height={36} alt="Logo" />
								</Icon>
								<span className={classes.vertical_align}>{t('Collapse')}</span>
							</span>
							<span color="grey">
								<span>
									{t('plpp-data.manageDataColumns.columns.tensile_test') +
										' [' +
										retrieveAllTensileTestColumns().length +
										'] '}
								</span>
							</span>
							<span
								onClick={() => setIsTensileTestGroupExpanded(true)}
								className={[classes.expand_btn, isTensileTestGroupExpanded ? classes.grey : classes.green].join(
									' '
								)}
							>
								<span className={classes.vertical_align}>{t('Extend')}</span>
								<Icon fontSize={'large'} className={classes.vertical_align}>
									<img src={expandRight} height={36} alt="Logo" />
								</Icon>
							</span>
						</th>
					)
				}
				break
			case 'hardnessTest':
				if (hardnessTestColumns.length > 0) {
					return (
						<th
							colSpan={hardnessTestColumns.length}
							className={[
								classes.groupHeader,
								isHardnessTestGroupExpanded ? classes.backgroundExtended : classes.noBackground
							].join(' ')}
						>
							<span
								onClick={() => setIsHardnessTestGroupExpanded(false)}
								className={[
									classes.collapse_btn,
									isHardnessTestGroupExpanded ? classes.red : classes.grey
								].join(' ')}
							>
								<Icon fontSize={'large'} className={classes.vertical_align}>
									<img src={expandLeft} height={36} alt="Logo" />
								</Icon>
								<span className={classes.vertical_align}>{t('Collapse')}</span>
							</span>
							<span color="grey">
								<span>
									{t('plpp-data.manageDataColumns.columns.hardness_test') +
										' [' +
										retrieveAllHardnessTestColumns().length +
										'] '}
								</span>
							</span>
							<span
								onClick={() => setIsHardnessTestGroupExpanded(true)}
								className={[
									classes.expand_btn,
									isHardnessTestGroupExpanded ? classes.grey : classes.green
								].join(' ')}
							>
								<span className={classes.vertical_align}>{t('Extend')}</span>
								<Icon fontSize={'large'} className={classes.vertical_align}>
									<img src={expandRight} height={36} alt="Logo" />
								</Icon>
							</span>
						</th>
					)
				}
				break
		}
	}
	const RenderHeaderWithExpand = () => {
		return (
			<TableRow id="headerExpand">
				<th className={classes.noBackground}></th>
				{RenderHeaderBetweenGroups(null, -1)}
				{groupsOrder.map((group, index) => {
					return [RenderGroups(group), RenderHeaderBetweenGroups(group, index)]
				})}
				{RenderHeaderBetweenGroups(groupsOrder[groupsOrder.length], groupsOrder.length)}
			</TableRow>
		)
	}

	const RenderHeaderWithExpandMemoized = useMemo(
		() => RenderHeaderWithExpand(),
		[
			columnsToDisplay // Render when the columns to display changes
		]
	)

	return pageLoading || pageLoadingFomDrawer ? (
		<LoadingOverlay open={true} />
	) : (
		<FilterableTable
			columns={columnsToDisplay}
			data={dataToDisplay}
			currentPageNumber={plppState.pageNumber}
			setCurrentPageNumber={(pageNumber: number) => plppDispatch({ type: 'set_page_number', pageNumber })}
			autoResetFilters={false}
			title={<ChipsFilters isPipeView={false} filters={plppState.filteredTestsColumnsMap} />}
			currentPageSize={plppState.pageSize}
			draggable={false}
			setCurrentPageSize={(pageSize: number) => plppDispatch({ type: 'set_page_size', pageSize })}
			components={{
				Header: propsHeader => (
					<div style={{ display: 'contents' }}>
						{containsHeaderWithExpand && RenderHeaderWithExpandMemoized}
						<MTableHeader {...propsHeader} />
					</div>
				)
			}}
			selectedData={plppState.selectedTestsPlppData}
			setSelectedRows={setSelectedRows}
		/>
	)
}

export default TestsPlppDataTable
