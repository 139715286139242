/** @format */

import { Button, Divider, Grid, IconButton, SvgIcon, TextField } from '@material-ui/core'
import Popover from '@material-ui/core/Popover'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Clear, FilterList, KeyboardArrowUp } from '@material-ui/icons'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Multiselect from '../../components/common/Multiselect'
import { PlppContext } from '../../stores/PlppStore'
import theme from '../../utils/theme'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		typography: {
			padding: theme.spacing(2)
		},
		button: {
			color: theme.palette.primary.main
		},
		root: {
			width: 'fit-content',
			border: `2px solid ${theme.palette.divider}`,
			borderRadius: theme.shape.borderRadius,
			backgroundColor: theme.palette.background.paper,
			color: theme.palette.text.secondary,
			'& svg': {
				margin: theme.spacing(1.5)
			},
			'& hr': {
				margin: theme.spacing(0, 0.5)
			}
		},
		multiselects: {
			marginLeft: theme.spacing(1)
		},
		icon: {
			right: 0,
			position: 'absolute',
			transform: 'translateY(-25%)'
		}
	})
)

const PopoverFilter = props => {
	const classes = useStyles()

	const { plppState } = useContext(PlppContext)
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

	const [selectedValues, setSelectedItemValues] = React.useState<string[]>()
	const [min, setMin] = useState('')
	const [max, setMax] = useState('')
	const [value, setValue] = useState('')
	const { t } = useTranslation()

	const handleOpenPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
		const filteredColumnsMap = props.isPipeView ? plppState.filteredColumnsMap : plppState.filteredTestsColumnsMap
		setSelectedItemValues(
			filteredColumnsMap === undefined ||
				filteredColumnsMap.get(props.columnTitle) === undefined ||
				filteredColumnsMap.get(props.columnTitle).values.length === 0
				? []
				: filteredColumnsMap.get(props.columnTitle).values.map(val => {
						return { id: val, display: val }
				  })
		)

		document.getElementsByTagName('th')[props.columnDef.tableData.id + 1].style.color = theme.palette.primary.main
		setAnchorEl(event.currentTarget)
	}

	const handleClosePopup = () => {
		document.getElementsByTagName('th')[props.columnDef.tableData.id + 1].style.color = '#000000'
		setAnchorEl(null)
	}

	useEffect(() => {
	    filteredColumnsMap = props.isPipeView ? plppState.filteredColumnsMap : plppState.filteredTestsColumnsMap
		const col = filteredColumnsMap.get(props.columnTitle)
		if(col !== undefined )
			setMinMaxValueSelect(col.min, col.max, col.value ? col.value : '', col.values ? col.values : [])
	}, [])
	const open = Boolean(anchorEl)
	const id = open ? 'simple-popover' : undefined
	let filteredColumnsMap = props.isPipeView ? plppState.filteredColumnsMap : plppState.filteredTestsColumnsMap
	const hasFilter =
		min.length !== 0 ||
		max.length !== 0 ||
		value.length !== 0 ||
		(filteredColumnsMap !== undefined &&
			filteredColumnsMap.get(props.columnTitle) !== undefined &&
			(filteredColumnsMap.get(props.columnTitle).values.length !== 0 ||
			filteredColumnsMap.get(props.columnTitle).min.length !== 0 ||
			filteredColumnsMap.get(props.columnTitle).max.length !== 0 ))



	const setMinMaxValueSelect = (min: string, max: string, value: string, selected: string[]) => {
		setMin(min)
		setMax(max)
		setValue(value)
		setSelectedItemValues(selected)
	}
	useEffect(() => {
	    filteredColumnsMap = props.isPipeView ? plppState.filteredColumnsMap : plppState.filteredTestsColumnsMap
		const col = filteredColumnsMap.get(props.columnTitle)
		if(col !== undefined )
			setMinMaxValueSelect(col.min, col.max, col.value ? col.value : '', col.values ? col.values : [])
		else{
			setMinMaxValueSelect('', '', '', [])
		}
	}, [plppState])

	const handleClearWithoutApply = () => {
		setMinMaxValueSelect('', '', '', [])
		// hack to force the input to really be cleared
		setTimeout(function () {
			setMinMaxValueSelect('', '', '', [])
		}, 100)
	}

	const handleClear = () => {
		handleClearWithoutApply()
		props.onFilterChanged(props.columnDef.tableData.id, { clear: true })
		handleClosePopup()
	}

	const handleApply = () => {
		const formattedSelectedValues = selectedValues.map((select: any) => select.display).join('|')
		props.onFilterChanged(props.columnDef.tableData.id, {
			value: value.length > 0 ? value : selectedValues.length > 0 ? formattedSelectedValues : '',
			min: min,
			max: max
		})
		handleClosePopup()
	}

	const handleMultiSelectApply = (values: any[]) => {
		setSelectedItemValues(values)
	}

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				width: '100%',
				height: '2vh'
			}}
		>
			<IconButton aria-describedby={id} color="primary" onClick={handleOpenPopup}>
				{open ? (
					<KeyboardArrowUp fontSize="large" />
				) : hasFilter ? (
					<FilteredIcon style={{ marginTop: '5px', marginBottom: '6px' }} />
				) : (
					<FilterList style={{ marginTop: '5px', marginBottom: '6px' }} />
				)}
			</IconButton>
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClosePopup}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
			>
				<div>
					<Grid container alignItems="center" className={classes.root} style={{ padding: '20px' }}>
						<TextField
							value={value}
							onChange={e => {
								setMinMaxValueSelect('', '', e.target.value, [])
							}}
							id="value"
							label="Value"
							style={{ marginBottom: '10px', width: '15vh' }}
						/>

						<IconButton size="small" id="value_filter_clear" onClick={handleClearWithoutApply}>
							<Clear />
						</IconButton>

						{!props.isAlphanumeric && <Divider orientation="vertical" flexItem />}

						{!props.isAlphanumeric && (
							<div>
								<TextField
									value={min}
									onChange={e => {
										setMinMaxValueSelect(e.target.value, max, '', [])
									}}
									type="number"
									id="min"
									label="Min"
									style={{ marginLeft: '12px', width: '9vh' }}
								/>

								<IconButton
									size="small"
									onClick={() => {
										setMinMaxValueSelect('', max, '', [])
									}}
									id="max_filter_clear"
								>
									<Clear />
								</IconButton>

								<TextField
									value={max}
									onChange={e => {
										setMinMaxValueSelect(min, e.target.value, '', [])
									}}
									type="number"
									id="max"
									label="Max"
									style={{ marginLeft: '10px', width: '9vh' }}
								/>

								<IconButton
									size="small"
									onClick={() => {
										setMinMaxValueSelect(min, '', '', [])
									}}
									id="min_filter_clear"
								>
									<Clear />
								</IconButton>
							</div>
						)}

						<Divider orientation="vertical" flexItem />

						<div className={classes.multiselects}>
							<Multiselect
								iconStyle={classes.icon}
								handleApply={handleMultiSelectApply}
								inputLabel={'Select value'}
								selectedOptions={selectedValues}
								allOptions={
									open
										? getAllOptions(plppState, props).sort((a, b) => a.display.localeCompare(b.display))
										: []
								}
							/>
						</div>

						<IconButton size="small" id="values_filter_clear" onClick={handleClearWithoutApply}>
							<Clear />
						</IconButton>

						<Divider orientation="vertical" flexItem />

						<Button
							className={classes.button}
							style={{ marginLeft: '10px', marginRight: '10px' }}
							onClick={handleClear}
						>
							{t('CLEAR')}
						</Button>

						<Button className={classes.button} style={{ marginRight: '10px' }} onClick={handleApply}>
							{t('APPLY')}
						</Button>
					</Grid>
				</div>
			</Popover>
		</div>
	)
}

function getAllOptions(plppState: any, props: any) {
	return (props.isPipeView ? plppState.displayedPlppData : plppState.displayedTestsPlppData)
		.map(row => {
			let val = retrieveValueFromStringKey(props.columnDef.field, row)

			return {
				id: val,
				display: val
			}
		})
		.filter(
			(elem, index, self) =>
				index === self.findIndex(t => t.display === elem.display) &&
				elem.display !== 'undefined' &&
				elem.display !== 'null'
		)
}

export function retrieveValueFromStringKey(key: any, row: any) {
	let val = ''
	if (key.startsWith('carbonSteelItemPlpp') && row['carbonSteelItemPlpp']) {
		if (key.startsWith('carbonSteelItemPlpp.carbonSteelOrder') && row['carbonSteelItemPlpp']['carbonSteelOrder']) {
			if (
				key.startsWith('carbonSteelItemPlpp.carbonSteelOrder.carbonSteelManufacturer') &&
				row['carbonSteelItemPlpp']['carbonSteelOrder']['carbonSteelManufacturer']
			) {
				val = String(
					row['carbonSteelItemPlpp']['carbonSteelOrder']['carbonSteelManufacturer'][
						key.replace('carbonSteelItemPlpp.carbonSteelOrder.carbonSteelManufacturer.', '')
					]
				)
			} else {
				val = String(
					row['carbonSteelItemPlpp']['carbonSteelOrder'][key.replace('carbonSteelItemPlpp.carbonSteelOrder.', '')]
				)
			}
		} else {
			val = String(row['carbonSteelItemPlpp'][key.replace('carbonSteelItemPlpp.', '')])
		}
	} else if (key.startsWith('carbonSteelCertificate') && row['carbonSteelCertificate']) {
		val = String(row['carbonSteelCertificate'][key.replace('carbonSteelCertificate.', '')])
	} else if (key.startsWith('carbonSteelHeat') && row['carbonSteelHeat']) {
		val = String(row['carbonSteelHeat'][key.replace('carbonSteelHeat.', '')])
		if (key.startsWith('carbonSteelHeat.chemicalAnalysis') && row['carbonSteelHeat']['chemicalAnalysis']) {
			val = String(
				row['carbonSteelHeat']['chemicalAnalysis'][key.replace('carbonSteelHeat.chemicalAnalysis.', '')]
			)
		} else if (key.startsWith('carbonSteelHeat.tensileTest') && row['carbonSteelHeat']['tensileTest']) {
			val = String(
				row['carbonSteelHeat']['tensileTest'][key.replace('carbonSteelHeat.tensileTest.', '')]
			)
		}  else if (key.startsWith('carbonSteelHeat.impactTest') && row['carbonSteelHeat']['impactTest']) {
			val = String(
				row['carbonSteelHeat']['impactTest'][key.replace('carbonSteelHeat.impactTest.', '')]
			)
		}   else if (key.startsWith('carbonSteelHeat.hardnessTest') && row['carbonSteelHeat']['hardnessTest']) {
         	val = String(
         		row['carbonSteelHeat']['hardnessTest'][key.replace('carbonSteelHeat.hardnessTest.', '')]
       		)
  	    }
	} else {
		val = String(row[key.toString()])
	}
	return val
}

function FilteredIcon(props) {
	return (
		<SvgIcon {...props}>
			<path d="M14,12V19.88C14.04,20.18 13.94,20.5 13.71,20.71C13.32,21.1 12.69,21.1 12.3,20.71L10.29,18.7C10.06,18.47 9.96,18.16 10,17.87V12H9.97L4.21,4.62C3.87,4.19 3.95,3.56 4.38,3.22C4.57,3.08 4.78,3 5,3V3H19V3C19.22,3 19.43,3.08 19.62,3.22C20.05,3.56 20.13,4.19 19.79,4.62L14.03,12H14Z" />
		</SvgIcon>
	)
}

export default React.memo(PopoverFilter)
