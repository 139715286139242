/** @format */

import React, { Dispatch, useReducer } from 'react'
import { toast } from 'react-toastify'
import { retrieveValueFromStringKey } from '../containers/plpp/PopoverFilter'
import { getAllKeys } from '../utils/columnsOrderingUtils'
import { sortByPosition } from '../utils/sort-utils'

export type PlppColumn = {
	filterComponent?: any
	render?: any
	defaultSort?: string
	isMandatoryPipeView?: boolean
	isMandatoryTestView?: boolean
	isForPipeView: boolean
	isForTestView: boolean
	hasSpecificDisplay?: boolean
	chemicalAnalysisGroup?: boolean
	tensileTestGroup?: boolean
	impactTestGroup?: boolean
	hardnessTestGroup?: boolean
	position: number
	visible: boolean
	key: string
	title?: string
	field?: string
}

type State = {
	isFirstLoading: boolean
	isNeedToRefreshPlppData: boolean
	selectedTab: string
	// Columns
	isNeedToRefreshPlppColumns: number
	plppColumns: PlppColumn[]
	showEmptyColumns: boolean
	columnsOrderHasChanged: number
	// Pipes viewing mode
	allPlppData: any[]
	allPlppDataForSelectedOrderItems: any[]
	displayedPlppData: any[]
	selectedPlppData: any[]
	filteredColumnsMap: Map<string, any>
	hasClickOnViewTestsFromSelection: boolean
	// Tests viewing mode
	isFinishedLoadingTestsPlppData: boolean
	allTestsPlppData: any[]
	allTestsPlppDataForSelectedOrderItems: any[]
	displayedTestsPlppData: any[]
	selectedTestsPlppData: any[]
	filteredTestsColumnsMap: Map<string, any>
	testsDataToDisplayHaveNotBeenLoadedYet: boolean
	hasClickOnViewPipesFromSelection: boolean
	// Orders and items
	allOrders: any[]
	selectedOrderIds: string[]
	allItems: any[]
	allItemsForSelectedOrders: any[]
	selectedItemIds: string[]
	// Page
	pageNumber: number
	pageSize: number
	// Template
	allTemplates: any[]
	selectedTemplateId: string
	displaySaveColumnsOrderPanel: boolean
	onTemplateUpdate: number
}

type Action =
	| { type: 'set_is_first_loading'; isFirstLoading: boolean }
	| { type: 'set_is_need_to_refresh_plpp_data'; isNeedToRefreshPlppData: boolean }
	// Columns
	| { type: 'set_show_empty_columns'; showEmptyColumns: boolean }
	| { type: 'set_is_need_to_refresh_plpp_columns' }
	| { type: 'reset_is_need_to_refresh_plpp_columns' }
	| { type: 'update_plpp_columns_visibility' }
	| { type: 'set_selected_plpp_columns'; selectedColumns: any[] }
	| { type: 'add_selected_plpp_columns'; columnsToAdd: string[] }
	| { type: 'remove_selected_plpp_columns'; columnsToRemove: string[] }
	| {
			type: 'set_filtered_columns_map'
			filteredColumnsMap: Map<string, any>
			filteredTestsColumnsMap: Map<string, any>
	  }
	| { type: 'columns_order_has_changed' }
	| { type: 'reset_columns_order_has_changed' }
	| { type: 'move_columns'; sourceIndex: number; targetIndex: number; columnsToMove: PlppColumn[] }
	| { type: 'remove_filter_from_columns_map'; filterToRemoveFromPipeView: string; filterToRemoveFromTestView: string }
	| { type: 'reset_columns_to_ones_defined_in_template' }
	// Orders and items
	| { type: 'set_all_orders'; allOrders: any[] }
	| { type: 'init_selected_order_ids'; selectedOrderIds: string[] }
	| { type: 'init_selected_item_ids'; selectedItemIds: string[] }
	| { type: 'reverse_all_items' }
	| { type: 'set_selected_order_ids_from_all_plpp_data'; selectedOrderIds: string[] }
	| { type: 'set_selected_item_ids_from_all_plpp_data'; selectedItemIds: string[] }
	// Pipes viewing mode
	| { type: 'set_all_plpp_data'; allPlppData: any[] }
	| { type: 'set_displayed_plpp_data'; displayedPlppData: any[] }
	| { type: 'set_displayed_plpp_data_and_refresh'; displayedPlppData: any[] }
	| { type: 'set_selected_plpp_data'; selectedPlppData: any[] }
	// Tests viewing mode
	| { type: 'set_is_finished_loading_tests_plpp_data'; isFinishedLoadingTestsPlppData: boolean }
	| { type: 'set_tests_data_to_display_have_been_loaded' }
	| { type: 'set_all_tests_plpp_data'; allTestsPlppData: any[] }
	| { type: 'set_displayed_tests_plpp_data'; displayedTestsPlppData: any[] }
	| { type: 'set_displayed_tests_plpp_data_and_refresh'; displayedTestsPlppData: any[] }
	| { type: 'set_selected_tests_plpp_data'; selectedTestsPlppData: any[] }
	| { type: 'switch_to_pipe_view' }
	| { type: 'switch_to_test_view' }
	// Page
	| { type: 'set_page_number'; pageNumber: number }
	| { type: 'set_page_size'; pageSize: number }
	| { type: 'init_plpp_properties' }
	| { type: 'tab_has_changed'; tabToDisplay: string }
	// Template
	| { type: 'set_all_templates'; allTemplates: any[] }
	| { type: 'set_selected_template_id'; selectedTemplateId: string }
	| { type: 'display_save_columns_order_panel'; display: boolean }
	| { type: 'on_template_update' }

/**
 * @returns The initial list of plppColumns (all columns that do not have a specific display)
 */
const initPlppColumns = (): PlppColumn[] => {
	return getAllKeys().map(key => {
		return {
			field: key.label,
			key: key.label,
			defaultSort: key.defaultSort,
			isForPipeView: key.isForPipeView,
			hasSpecificDisplay: key.hasSpecificDisplay,
			isForTestView: key.isForTestView,
			isMandatoryPipeView: key.isMandatoryPipeView,
			isMandatoryTestView: key.isMandatoryTestView,
			chemicalAnalysisGroup: key.chemicalAnalysisGroup,
			tensileTestGroup: key.tensileTestGroup,
			impactTestGroup: key.impactTestGroup,
			hardnessTestGroup: key.hardnessTestGroup,
			visible: key.visible,
			position: key.position
		}
	})
}

const initialState: State = {
	isFirstLoading: true,
	isNeedToRefreshPlppData: true,
	selectedTab: 'pipes', // Default tab is pipes one
	// Columns
	isNeedToRefreshPlppColumns: 0,
	showEmptyColumns: true,
	plppColumns: initPlppColumns(),
	columnsOrderHasChanged: 0,
	// Pipes viewing mode
	allPlppData: [],
	allPlppDataForSelectedOrderItems: [],
	displayedPlppData: [],
	selectedPlppData: [],
	filteredColumnsMap: new Map(),
	// For tests viewing mode
	isFinishedLoadingTestsPlppData: false,
	allTestsPlppData: [],
	allTestsPlppDataForSelectedOrderItems: [],
	displayedTestsPlppData: [],
	selectedTestsPlppData: [],
	filteredTestsColumnsMap: new Map(),
	testsDataToDisplayHaveNotBeenLoadedYet: true,
	hasClickOnViewPipesFromSelection: false,
	hasClickOnViewTestsFromSelection: false,
	// Orders and items
	allOrders: [],
	selectedOrderIds: [],
	allItems: [],
	allItemsForSelectedOrders: [],
	selectedItemIds: [],
	// Page
	pageNumber: 0,
	pageSize: 10,
	// Template
	allTemplates: [],
	selectedTemplateId: '',
	displaySaveColumnsOrderPanel: false,
	onTemplateUpdate:  0
}

export const PlppContext = React.createContext<{
	plppState: State
	plppDispatch: Dispatch<Action>
}>({
	plppState: initialState,
	plppDispatch: () => null
})

const reducer = (state: State, action: Action): State => {
	let allItemsForSelectedOrders
	let dataToDisplay
	let testsDataToDisplay
	let filteredDataToDisplay
	let filteredTestsDataToDisplay
	let heatNumbers: string[]
	let heatFilter

	switch (action.type) {
		case 'set_is_first_loading':
			return {
				...state,
				isFirstLoading: action.isFirstLoading
			}
		case 'set_is_need_to_refresh_plpp_data':
			return {
				...state,
				isNeedToRefreshPlppData: action.isNeedToRefreshPlppData
			}
		// Columns
		case 'set_show_empty_columns':
			return {
				...state,
				showEmptyColumns: action.showEmptyColumns
			}
		case 'set_is_need_to_refresh_plpp_columns':
			return {
				...state,
				isNeedToRefreshPlppColumns: state.isNeedToRefreshPlppColumns + 1
			}
		case 'reset_is_need_to_refresh_plpp_columns':
			return {
				...state,
				isNeedToRefreshPlppColumns: 0
			}
		case 'update_plpp_columns_visibility':
			return {
				...state,
				isNeedToRefreshPlppColumns: state.isNeedToRefreshPlppColumns + 1
			}
		case 'set_selected_plpp_columns':
			// Go back to defaultColumn initial state to update only differents columns from template
			const selectedColumnsToUpdate = initPlppColumns()
			// Update all columns visibility
			selectedColumnsToUpdate.forEach(column =>
				action.selectedColumns.forEach(selectedColumn => {
					if (selectedColumn.label === column.key) {
						column.visible = selectedColumn.visible
						column.position = selectedColumn.position
					}
				})
			)
			return {
				...state,
				plppColumns: selectedColumnsToUpdate,
				isNeedToRefreshPlppColumns: state.isNeedToRefreshPlppColumns + 1
			}
		case 'add_selected_plpp_columns':
			action.columnsToAdd.forEach(columnToAdd => {
				const colToAdd: PlppColumn = state.plppColumns.find(c => c.key === columnToAdd)
				if (colToAdd) {
					colToAdd.visible = true
				}
			})
			return {
				...state,
				isNeedToRefreshPlppColumns: state.isNeedToRefreshPlppColumns + 1
			}
		case 'remove_selected_plpp_columns':
			action.columnsToRemove.forEach(columnsToRemove => {
				const colToAdd: PlppColumn = state.plppColumns.find(c => c.key === columnsToRemove)
				if (colToAdd) {
					colToAdd.visible = false
				}
			})
			return {
				...state,
				isNeedToRefreshPlppColumns: state.isNeedToRefreshPlppColumns + 1
			}
		case 'set_filtered_columns_map':
			let filtersForPipeView = new Map(state.filteredColumnsMap)
			let filtersForTestView = new Map(state.filteredTestsColumnsMap)
			let selectedPlppDataAfterFilterChanged = state.selectedPlppData
			let selectedTestsPlppDataAfterFilterChanged = state.selectedTestsPlppData
			// Add the action filter on concerned map
			if (action.filteredColumnsMap.size > 0) {
				let newChange: boolean = false
				action.filteredColumnsMap.forEach((value, key) => {
					if (
						!filtersForPipeView.has(key) ||
						filtersForPipeView.get(key).min !== value.min ||
						filtersForPipeView.get(key).max !== value.max ||
						filtersForPipeView.get(key).values.toString() !== value.values.toString()
					) {
						// Add the new filter only if it did not already exist
						filtersForPipeView.set(key, value)

						// There is a change related to new filter
						newChange = true
					}
				})

				// Update data to display only if a change has been done
				if (newChange) {
					let dataToDisplay = state.displayedPlppData
					let filtersToApply = action.filteredColumnsMap
					action.filteredColumnsMap.forEach((value, key) => {
						// If the filtered column already had a filter
						if (filtersForPipeView.has(key)) {
							// Then we need to use all pipe data instead of only previously displayed pipe data
							// because the range of eligible data to display may have increased with the new value
							// ex: we have now defined a smaller minimum value, so we have to display more data than before
							// and those new data are not part of displayedPlppData
							dataToDisplay = state.allPlppDataForSelectedOrderItems
							filtersToApply = new Map(filtersForPipeView)
						}
					})

					// apply new filter to already filtered data
					filteredDataToDisplay = updateFiltersForDisplayedPlppData(filtersToApply, dataToDisplay)
					selectedPlppDataAfterFilterChanged = state.selectedPlppData.filter(row => {
						return filteredDataToDisplay.includes(row)
					})
					displayToast(selectedPlppDataAfterFilterChanged, null)
				} else {
					filteredDataToDisplay = state.displayedPlppData
				}

				// No change on test data to display
				filteredTestsDataToDisplay = state.displayedTestsPlppData
			} else if (action.filteredTestsColumnsMap.size > 0) {
				let newChange: boolean = false
				action.filteredTestsColumnsMap.forEach((value, key) => {
					if (
						!filtersForTestView.has(key) ||
						filtersForTestView.get(key).min !== value.min ||
						filtersForTestView.get(key).max !== value.max ||
						filtersForTestView.get(key).values.toString() !== value.values.toString()
					) {
						// Add the new filter only if it did not already exist
						filtersForTestView.set(key, value)

						// There is a change related to new filter
						newChange = true
					}
				})

				// Update data to display only if a change has been done
				if (newChange) {
					let dataToDisplay = state.displayedTestsPlppData
					let filtersToApply = action.filteredTestsColumnsMap
					action.filteredTestsColumnsMap.forEach((value, key) => {
						// If the filtered column already had a filter
						if (filtersForTestView.has(key)) {
							// Then we need to use all test data instead of only previously displayed test data
							// because the range of eligible data to display may have increased with the new value
							// ex: we have now defined a smaller minimum value, so we have to display more data than before
							// and those new data are not part of displayedTestsPlppData
							dataToDisplay = state.allTestsPlppDataForSelectedOrderItems
							filtersToApply = new Map(filtersForTestView)
						}
					})

					// apply new filter to already filtered data
					filteredTestsDataToDisplay = updateFiltersForDisplayedPlppData(filtersToApply, dataToDisplay)
					heatNumbers = filteredTestsDataToDisplay.map(plppData => plppData.carbonSteelHeat.number)
					selectedTestsPlppDataAfterFilterChanged = state.selectedTestsPlppData.filter(row => {
						return heatNumbers.includes(row.carbonSteelHeat.number)
					})
					displayToast(null, selectedTestsPlppDataAfterFilterChanged)
				} else {
					filteredTestsDataToDisplay = state.displayedTestsPlppData
				}

				// No change on PipeView data to display
				filteredDataToDisplay = state.displayedPlppData
			}

			return {
				...state,
				displayedPlppData: filteredDataToDisplay,
				displayedTestsPlppData: filteredTestsDataToDisplay,
				filteredColumnsMap: filtersForPipeView,
				filteredTestsColumnsMap: filtersForTestView,
				selectedPlppData: selectedPlppDataAfterFilterChanged,
				selectedTestsPlppData: selectedTestsPlppDataAfterFilterChanged
			}
		case 'columns_order_has_changed':
			return {
				...state,
				columnsOrderHasChanged: state.columnsOrderHasChanged + 1 // We increment the value in order to fire a columnsOrderHasChanged event in UseEffects
			}
		case 'reset_columns_order_has_changed':
			return {
				...state,
				columnsOrderHasChanged: 0 // Reset the value to 0 means columns order has not changed
			}
		case 'move_columns':
			// Sort by position, to ensure columns index matches their ".position" attribute
			const columns: PlppColumn[] = state.plppColumns.sort(sortByPosition)

			// remove the group column (or the single column contained in groupColumn)
			columns.splice(action.sourceIndex, action.columnsToMove.length)

			if (action.targetIndex > action.sourceIndex) {
				// If we want to move a group of 3 columns from position 4 to 10,
				// after the removal of 3 columns done just above, the target index is not 10 anymore but 7 (10-3)
				action.targetIndex = action.targetIndex - action.columnsToMove.length
			}

			// insert the group column or single column at target position
			if (action.columnsToMove.length > 0) {
				action.columnsToMove.forEach(column => {
					columns.splice(action.targetIndex, 0, column)
					action.targetIndex += 1
				})
			}
			// Recompute the position, because as we moved columns, their position as changed
			columns.forEach((col, index) => (col.position = index))
			return {
				...state,
				columnsOrderHasChanged: state.columnsOrderHasChanged + 1, // We increment the value in order to fire a columnsOrderHasChanged event in UseEffects
				plppColumns: columns
			}
		case 'remove_filter_from_columns_map':
			const filtersForPipeView2 = state.filteredColumnsMap
			const filtersForTestView2 = state.filteredTestsColumnsMap
			let selectedPlppDataAfterFilterRemoved = state.selectedPlppData
			let selectedTestsPlppDataAfterFilterRemoved = state.selectedTestsPlppData
			if (action.filterToRemoveFromPipeView) {
				if (filtersForPipeView2.has(action.filterToRemoveFromPipeView)) {
					filtersForPipeView2.delete(action.filterToRemoveFromPipeView)

					// Apply the new filter on pipe data
					filteredDataToDisplay = updateFiltersForDisplayedPlppData(
						filtersForPipeView2,
						state.allPlppDataForSelectedOrderItems
					)
					filteredTestsDataToDisplay = state.displayedTestsPlppData

					selectedPlppDataAfterFilterRemoved = state.selectedPlppData.filter(row => {
						return filteredDataToDisplay.includes(row)
					})
					filteredDataToDisplay.forEach(row => {
						if (selectedPlppDataAfterFilterRemoved.map(row => row.id).includes(row.id)) {
							row.tableData = { id: row.id, checked: true }
						} else {
							row.tableData = { id: row.id, checked: false }
						}
					})
				} else {
					filteredDataToDisplay = state.displayedPlppData
					filteredTestsDataToDisplay = state.displayedTestsPlppData
				}
			} else if (action.filterToRemoveFromTestView) {
				if (filtersForTestView2.has(action.filterToRemoveFromTestView)) {
					filtersForTestView2.delete(action.filterToRemoveFromTestView)

					// Apply the new filter on test data
					filteredTestsDataToDisplay = updateFiltersForDisplayedPlppData(
						filtersForTestView2,
						state.allTestsPlppDataForSelectedOrderItems
					)
					heatNumbers = filteredTestsDataToDisplay.map(plppData => plppData.carbonSteelHeat.number)
					selectedTestsPlppDataAfterFilterRemoved = state.selectedTestsPlppData.filter(row => {
						return heatNumbers.includes(row.carbonSteelHeat.number)
					})
					filteredDataToDisplay = state.displayedPlppData
					filteredTestsDataToDisplay.forEach((row, index) => {
						if (
							state.selectedTestsPlppData
								.map(row => JSON.stringify(row.carbonSteelHeat))
								.includes(JSON.stringify(row.carbonSteelHeat))
						) {
							row.tableData = { id: index, checked: true }
						} else {
							row.tableData = { id: index, checked: false }
						}
					})
				} else {
					filteredDataToDisplay = state.displayedPlppData
					filteredTestsDataToDisplay = state.displayedTestsPlppData
				}
			}

			return {
				...state,
				displayedPlppData: filteredDataToDisplay,
				displayedTestsPlppData: filteredTestsDataToDisplay,
				filteredColumnsMap: filtersForPipeView2,
				filteredTestsColumnsMap: filtersForTestView2,
				selectedPlppData: selectedPlppDataAfterFilterRemoved,
				selectedTestsPlppData: selectedTestsPlppDataAfterFilterRemoved
			}
		case 'reset_columns_to_ones_defined_in_template':
			// Retrieve all columns defined in selected template
			const selectedColumnsFromSelectedTemplate: any[] = state.allTemplates.find(
				t => t.id === state.selectedTemplateId
			).carbonSteelColumns

			// Go back to defaultColumn initial state to update only differents columns from template
			const columnsToUpdate = initPlppColumns()
			// Update all columns visibility
			columnsToUpdate.forEach(column =>
				selectedColumnsFromSelectedTemplate.forEach(selectedColumn => {
					if (selectedColumn.label === column.key) {
						column.visible = selectedColumn.visible
						column.position = selectedColumn.position
					}
				})
			)
			return {
				...state,
				plppColumns: columnsToUpdate,
				isNeedToRefreshPlppColumns: state.isNeedToRefreshPlppColumns + 1
			}
		// Orders and items
		case 'set_all_orders':
			const allOrders = action.allOrders.map(order => ({
				...order,
				display: `${order.customerProjectName} - ${order.vallourecOrderReference}`,
				items: order.carbonSteelItemPlpps.map(item => ({
					...item,
					display: `${order.customerProjectName} - ${item.itemDescription} - ${item.vallourecItemReference}`
				}))
			}))
			return {
				...state,
				allOrders,
				allItems: allOrders
					.map(order => order.items)
					.flat()
					.sort(sortItem)
			}
		case 'init_selected_order_ids':
			allItemsForSelectedOrders = state.allOrders
				.filter(
					order =>
						action.selectedOrderIds.includes(order.id) ||
						action.selectedOrderIds.includes(order.vallourecOrderReference)
				)
				.map(order => order.items)
				.flat()
				.sort(sortItem)

			const itemsId = state.selectedItemIds.filter(itemId =>
				allItemsForSelectedOrders.find(item => item.id === itemId)
			)
			filteredDataToDisplay = updateFiltersForDisplayedPlppData(
				state.filteredColumnsMap,
				getDisplayedPlppData(state.displayedPlppData, itemsId, state.selectedPlppData)
			)

			heatNumbers = filteredDataToDisplay.map(plppData => plppData.carbonSteelHeat.number)
			filteredTestsDataToDisplay = updateFiltersForDisplayedPlppData(
				state.filteredTestsColumnsMap,
				getDisplayedTestsPlppData(state.displayedTestsPlppData, heatNumbers, state.selectedTestsPlppData)
			)

			return {
				...state,
				selectedOrderIds: action.selectedOrderIds,
				allItemsForSelectedOrders,
				selectedItemIds: itemsId,
				displayedPlppData: filteredDataToDisplay,
				displayedTestsPlppData: filteredTestsDataToDisplay
			}
		case 'init_selected_item_ids':
			return {
				...state,
				selectedItemIds: action.selectedItemIds
			}
		case 'reverse_all_items':
			return {
				...state,
				allItemsForSelectedOrders: state.allItemsForSelectedOrders.reverse()
			}
		case 'set_selected_order_ids_from_all_plpp_data':
			allItemsForSelectedOrders = state.allOrders
				.filter(order => (action.selectedOrderIds.length > 0 ? action.selectedOrderIds.includes(order.id) : false))
				.map(order => order.items)
				.flat()
				.sort(sortItem)
			const selectedItemIds = state.selectedItemIds.filter(itemId =>
				action.selectedOrderIds.length > 0 ? allItemsForSelectedOrders.find(item => item.id === itemId) : false
			)

			dataToDisplay = getDisplayedPlppData(state.allPlppData, selectedItemIds, state.selectedPlppData)
			filteredDataToDisplay = updateFiltersForDisplayedPlppData(state.filteredColumnsMap, dataToDisplay)

			heatNumbers = filteredDataToDisplay.map(plppData => plppData.carbonSteelHeat.number)
			testsDataToDisplay = getDisplayedTestsPlppData(
				state.allTestsPlppData,
				heatNumbers,
				state.selectedTestsPlppData
			)
			filteredTestsDataToDisplay = updateFiltersForDisplayedPlppData(
				state.filteredTestsColumnsMap,
				testsDataToDisplay
			)

			const selectedPlppDataAfterOrderChanged = state.selectedPlppData.filter(row => {
				return state.selectedOrderIds.includes(row.carbonSteelItemPlpp.carbonSteelOrder.id)
			})

			const selectedTestsPlppDataAfterOrderChanged = state.selectedTestsPlppData.filter(row => {
				return heatNumbers.includes(row.carbonSteelHeat.number)
			})

			displayToast(selectedPlppDataAfterOrderChanged, selectedTestsPlppDataAfterOrderChanged)

			return {
				...state,
				selectedOrderIds: action.selectedOrderIds,
				allItemsForSelectedOrders,
				selectedItemIds,
				allPlppDataForSelectedOrderItems: dataToDisplay,
				allTestsPlppDataForSelectedOrderItems: testsDataToDisplay,
				displayedPlppData: filteredDataToDisplay,
				displayedTestsPlppData: filteredTestsDataToDisplay,
				selectedPlppData: selectedPlppDataAfterOrderChanged,
				selectedTestsPlppData: selectedTestsPlppDataAfterOrderChanged
			}
		case 'set_selected_item_ids_from_all_plpp_data':
			dataToDisplay = getDisplayedPlppData(state.allPlppData, action.selectedItemIds, state.selectedPlppData)
			filteredDataToDisplay = updateFiltersForDisplayedPlppData(state.filteredColumnsMap, dataToDisplay)

			heatNumbers = filteredDataToDisplay.map(plppData => plppData.carbonSteelHeat.number)
			testsDataToDisplay = getDisplayedTestsPlppData(
				state.allTestsPlppData,
				heatNumbers,
				state.selectedTestsPlppData
			)
			filteredTestsDataToDisplay = updateFiltersForDisplayedPlppData(
				state.filteredTestsColumnsMap,
				testsDataToDisplay
			)

			const selectedPlppDataAfterItemChanged = state.selectedPlppData.filter(row => {
				return (
					action.selectedItemIds.includes(row.carbonSteelItemPlpp.id) &&
					state.selectedOrderIds.includes(row.carbonSteelItemPlpp.carbonSteelOrder.id)
				)
			})

			const selectedTestsPlppDataAfterItemChanged = state.selectedTestsPlppData.filter(row => {
				return heatNumbers.includes(row.carbonSteelHeat.number)
			})

			displayToast(selectedPlppDataAfterItemChanged, selectedTestsPlppDataAfterItemChanged)
			return {
				...state,
				selectedItemIds: action.selectedItemIds,
				allPlppDataForSelectedOrderItems: dataToDisplay,
				allTestsPlppDataForSelectedOrderItems: testsDataToDisplay,
				displayedPlppData: filteredDataToDisplay,
				displayedTestsPlppData: filteredTestsDataToDisplay,
				selectedPlppData: selectedPlppDataAfterItemChanged,
				selectedTestsPlppData: selectedTestsPlppDataAfterItemChanged
			}
		// Pipes viewing mode
		case 'set_all_plpp_data':
			return {
				...state,
				allPlppData: action.allPlppData
			}
		case 'set_displayed_plpp_data':
			return {
				...state,
				displayedPlppData: action.displayedPlppData,
				allPlppDataForSelectedOrderItems: action.displayedPlppData
			}
		case 'set_displayed_plpp_data_and_refresh':
			let displayedPlppDataFromAction = action.displayedPlppData

			displayedPlppDataFromAction.forEach(row => {
				if (state.selectedPlppData.map(row => row.id).includes(row.id)) {
					row.tableData = { id: row.id, checked: true }
				} else {
					row.tableData = { id: row.id, checked: false }
				}
			})

			const selectedPlppDataAfterChanged = state.selectedPlppData.filter(row => {
				return (
					state.selectedItemIds.includes(row.carbonSteelItemPlpp.id) &&
					state.selectedOrderIds.includes(row.carbonSteelItemPlpp.carbonSteelOrder.id)
				)
			})

			filteredDataToDisplay = updateFiltersForDisplayedPlppData(
				state.filteredColumnsMap,
				displayedPlppDataFromAction
			)
			displayToast(selectedPlppDataAfterChanged, null)

			return {
				...state,
				displayedPlppData: filteredDataToDisplay,
				allPlppDataForSelectedOrderItems: displayedPlppDataFromAction,
				selectedPlppData: selectedPlppDataAfterChanged
			}
		case 'set_selected_plpp_data':
			return {
				...state,
				selectedPlppData: action.selectedPlppData
			}
		// Tests viewing mode
		case 'set_is_finished_loading_tests_plpp_data':
			return {
				...state,
				isFinishedLoadingTestsPlppData: action.isFinishedLoadingTestsPlppData
			}
		case 'set_tests_data_to_display_have_been_loaded':
			return {
				...state,
				testsDataToDisplayHaveNotBeenLoadedYet: false
			}
		case 'set_all_tests_plpp_data':
			return {
				...state,
				allTestsPlppData: action.allTestsPlppData
			}
		case 'set_displayed_tests_plpp_data':
			return {
				...state,
				displayedTestsPlppData: action.displayedTestsPlppData.sort(sortTests),
				allTestsPlppDataForSelectedOrderItems: action.displayedTestsPlppData
			}
		case 'set_displayed_tests_plpp_data_and_refresh':
			testsDataToDisplay = action.displayedTestsPlppData.sort(sortTests)
			testsDataToDisplay.forEach((row, index) => {
				if (
					state.selectedTestsPlppData
						.map(row => JSON.stringify(row.carbonSteelHeat))
						.includes(JSON.stringify(row.carbonSteelHeat))
				) {
					row.tableData = { id: index, checked: true }
				} else {
					row.tableData = { id: index, checked: false }
				}
			})

			heatNumbers = testsDataToDisplay.map(plppData => plppData.carbonSteelHeat.number)
			const selectedTestsPlppDataAfterChanged = state.selectedTestsPlppData.filter(row => {
				return heatNumbers.includes(row.carbonSteelHeat.number)
			})

			displayToast(null, selectedTestsPlppDataAfterChanged)

			filteredTestsDataToDisplay = updateFiltersForDisplayedPlppData(
				state.filteredTestsColumnsMap,
				testsDataToDisplay
			)
			return {
				...state,
				displayedTestsPlppData: filteredTestsDataToDisplay,
				allTestsPlppDataForSelectedOrderItems: testsDataToDisplay,
				selectedTestsPlppData: selectedTestsPlppDataAfterChanged
			}
		case 'set_selected_tests_plpp_data':
			return {
				...state,
				selectedTestsPlppData: action.selectedTestsPlppData
			}
		case 'switch_to_pipe_view':
			dataToDisplay = []

			// Get selected heats without item
			const selectedHeatsWithoutItem = state.selectedTestsPlppData.filter(
				selectedTestPlppData => selectedTestPlppData.carbonSteelHeat.carbonSteelItemPlppId === undefined
			)

			const selectedHeatNumbersWithoutItem = selectedHeatsWithoutItem.map(heat => heat.carbonSteelHeat.number)
			dataToDisplay.push(
				...state.allPlppDataForSelectedOrderItems.filter(plppData =>
					selectedHeatNumbersWithoutItem.includes(plppData.carbonSteelHeat.number)
				)
			)

			// Get selected heats with item which are not already without item
			const selectedHeatsWithItem = state.selectedTestsPlppData.filter(
				selectedTestPlppData =>
					!selectedHeatNumbersWithoutItem.includes(selectedTestPlppData.carbonSteelHeat.number)
			)
			dataToDisplay.push(
				...state.allPlppDataForSelectedOrderItems.filter(
					plppData =>
						selectedHeatsWithItem.find(
							selectedHeat =>
								selectedHeat.carbonSteelHeat.number === plppData.carbonSteelHeat.number &&
								selectedHeat.carbonSteelHeat.carbonSteelItemPlppId === plppData.carbonSteelItemPlpp.id
						) !== undefined
				)
			)

			updateFiltersForDisplayedPlppData(state.filteredColumnsMap, dataToDisplay)

			return {
				...state,
				displayedPlppData: dataToDisplay,
				hasClickOnViewPipesFromSelection: true
			}
		case 'switch_to_test_view':
			dataToDisplay = []
			const selectedHeats = state.selectedPlppData
			dataToDisplay.push(
				...state.allTestsPlppDataForSelectedOrderItems.filter(
					testPlppData =>
						selectedHeats.find(
							selectedHeat => selectedHeat.carbonSteelHeat.number === testPlppData.carbonSteelHeat.number
						) !== undefined
				)
			)

			updateFiltersForDisplayedTestsPlppData(state.filteredTestsColumnsMap, dataToDisplay)
			return {
				...state,
				displayedTestsPlppData: dataToDisplay,
				hasClickOnViewTestsFromSelection: true
			}

		// Page
		case 'set_page_number':
			localStorage.setItem('page_number', JSON.stringify(action.pageNumber))
			return {
				...state,
				pageNumber: action.pageNumber
			}
		case 'set_page_size':
			localStorage.setItem('page_size', JSON.stringify(action.pageSize))
			return {
				...state,
				pageSize: action.pageSize
			}
		case 'init_plpp_properties':
			return {
				...state,
				pageNumber: localStorage.getItem('page_number') ? JSON.parse(localStorage.getItem('page_number')!) : 0,
				pageSize: localStorage.getItem('page_size') ? JSON.parse(localStorage.getItem('page_size')!) : 10
			}
		case 'tab_has_changed':
			switch (action.tabToDisplay) {
				case 'pipes':
					// Update filters for Pipes viewing mode
					let filtersForPipeView = new Map(state.filteredColumnsMap)

					// Retrieve all heats concerned by the filters applyed on Tests viewing mode
					if (state.filteredTestsColumnsMap.size > 0 || state.hasClickOnViewPipesFromSelection) {
						// If we are here because of a click on "view pipes from selection", then use selected row, otherwise use displayed row
						heatFilter = getHeatFilter(
							state.hasClickOnViewPipesFromSelection ? state.selectedTestsPlppData : state.displayedTestsPlppData
						)

						// Add the heat filter on filtersForPipeView
						filtersForPipeView.set('carbonSteelHeat.number', heatFilter)
					} else {
						filtersForPipeView.delete('carbonSteelHeat.number')
					}

					// Refresh the test view with the new heat filter
					filteredDataToDisplay = updateFiltersForDisplayedPlppData(
						filtersForPipeView,
						state.allPlppDataForSelectedOrderItems
					)

					return {
						...state,
						displayedPlppData: filteredDataToDisplay,
						filteredColumnsMap: filtersForPipeView,
						hasClickOnViewPipesFromSelection: false,
						selectedTab: action.tabToDisplay
					}
				case 'tests':
					let filtersForTestView = new Map(state.filteredTestsColumnsMap)

					if (state.filteredColumnsMap.size > 0 || state.hasClickOnViewTestsFromSelection) {
						heatFilter = getHeatFilter(
							state.hasClickOnViewTestsFromSelection ? state.selectedPlppData : state.displayedPlppData
						)
					}
					if (state.filteredColumnsMap.size > 0 || state.hasClickOnViewTestsFromSelection) {
						if (state.filteredColumnsMap.has('carbonSteelHeat.number')) {
							heatFilter = state.filteredColumnsMap.get('carbonSteelHeat.number')
						} else {
							heatFilter = getHeatFilter(
								state.hasClickOnViewTestsFromSelection ? state.selectedPlppData : state.displayedPlppData
							)
						}

						// Add the heat filter on filtersForTestView
						filtersForTestView.set('carbonSteelHeat.number', heatFilter)
					} else {
						filtersForTestView.delete('carbonSteelHeat.number')
					}

					// Refresh the test view with the new heat filter
					filteredTestsDataToDisplay = updateFiltersForDisplayedTestsPlppData(
						filtersForTestView,
						state.allTestsPlppDataForSelectedOrderItems
					)
					return {
						...state,
						displayedTestsPlppData: filteredTestsDataToDisplay,
						filteredTestsColumnsMap: filtersForTestView,
						hasClickOnViewTestsFromSelection: false,
						selectedTab: action.tabToDisplay
					}

				default:
					return {
						...state
					}
			}
		// Template
		case 'set_all_templates':
			return {
				...state,
				allTemplates: action.allTemplates
			}
		case 'set_selected_template_id':
			return {
				...state,
				selectedTemplateId: action.selectedTemplateId
			}
		case 'display_save_columns_order_panel':
			return {
				...state,
				displaySaveColumnsOrderPanel: action.display
			}
		case 'on_template_update':
			return {
				...state,
				onTemplateUpdate: state.onTemplateUpdate +1
			}
	}

	function displayToast(selectedPlppData: any[], selectedTestsPlppData: any[]) {
		const hasSelectedPipeChanged =
			selectedPlppData !== null
				? selectedPlppData.length !== state.selectedPlppData.length &&
				  selectedPlppData.every(function (element, index) {
						return element !== state.selectedPlppData[index]
				  })
				: false

		const hasSelectedTestsChanged =
			selectedTestsPlppData !== null
				? selectedTestsPlppData.length !== state.selectedTestsPlppData.length &&
				  selectedTestsPlppData.every(function (element, index) {
						return element !== state.selectedTestsPlppData[index]
				  })
				: false

		if (hasSelectedPipeChanged || hasSelectedTestsChanged) {
			toast.warn('The row selected are not part anymore of active projects/items, they will be unselected', {
				autoClose: 10000
			})
		}
	}
}

export const PlppProvider = (props: any) => {
	const [plppState, plppDispatch] = useReducer(reducer, initialState)

	return <PlppContext.Provider value={{ plppState, plppDispatch }}>{props.children}</PlppContext.Provider>
}

export const getDisplayedPlppData = (allPlppData: any[], displayedItems: string[], selectedPlppData: any[]): any[] => {
	if (displayedItems.length) {
		let filtered = allPlppData.filter(plppProduct => {
			return plppProduct !== undefined && displayedItems.indexOf(plppProduct.carbonSteelItemPlpp.id) !== -1
		})

		filtered.forEach(row => {
			if (selectedPlppData.includes(row)) {
				row.tableData = { id: row.id, checked: true }
			} else {
				row.tableData = { id: row.id, checked: false }
			}
		})

		return filtered
	}
	// Return an empty array if no item has been selected
	return []
}

export const getDisplayedTestsPlppData = (
	allTestsPlppData: any[],
	displayedHeats: string[],
	selectedTestsPlppData: any[]
) => {
	if (displayedHeats.length) {
		let filtered = allTestsPlppData.filter(testsPlppData => {
			return testsPlppData !== undefined && displayedHeats.indexOf(testsPlppData.carbonSteelHeat.number) !== -1
		})

		filtered.forEach((row, index) => {
			if (selectedTestsPlppData.includes(row)) {
				row.tableData = { id: index, checked: true }
			} else {
				row.tableData = { id: index, checked: false }
			}
		})

		return filtered
	}
	// Return an empty array if no item has been selected
	return []
}

export const getDistinctValues = (values: any[]) => {
	return values.filter((n, i) => values.map(value => value.id).indexOf(n.id) === i)
}

const updateFiltersForDisplayedPlppData = (filteredColumnMap: Map<string, any>, dataToDisplay: any[]) => {
	if (filteredColumnMap) {
		updateFilters(filteredColumnMap, dataToDisplay)
		filteredColumnMap.forEach((value: any, key: string) => {
			dataToDisplay = applyFilter(dataToDisplay, value.values, value.min, value.max, key)
		})
	}
	return dataToDisplay
}

const updateFiltersForDisplayedTestsPlppData = (filteredTestsColumnMap: Map<string, any>, dataToDisplay: any[]) => {
	if (filteredTestsColumnMap) {
		updateFilters(filteredTestsColumnMap, dataToDisplay)
		filteredTestsColumnMap.forEach((value: any, key: string) => {
			dataToDisplay = applyFilter(dataToDisplay, value.values, value.min, value.max, key)
		})
	}
	return dataToDisplay
}

const updateFilters = (filteredColumnMap: Map<string, any>, pipesToDisplay: any[]) => {
	if (filteredColumnMap !== undefined) {
		filteredColumnMap.forEach((value: any, key: string) => {
			if (value.values && value.values.length) {
				let allEligibleValuesForFilter: any[] = []

				if (key.includes('carbonSteelOrder')) {
					// Concerns the order
					if (key.includes('carbonSteelManufacturer')) {
						allEligibleValuesForFilter = pipesToDisplay
							.filter(aPipe => aPipe['carbonSteelManufacturer'])
							.map(aPipe => {
								return String(
									aPipe['carbonSteelManufacturer'][
										key.replace('carbonSteelItemPlpp.carbonSteelOrder.carbonSteelManufacturer.', '')
									]['carbonSteelManufacturer']
								)
							})
					} else {
						allEligibleValuesForFilter = pipesToDisplay
							.filter(aPipe => aPipe['carbonSteelItemPlpp'] && aPipe['carbonSteelItemPlpp']['carbonSteelOrder'])
							.map(aPipe => {
								return String(
									aPipe['carbonSteelItemPlpp']['carbonSteelOrder'][
										key.replace('carbonSteelItemPlpp.carbonSteelOrder.', '')
									]
								)
							})
					}
				} else if (key.includes('carbonSteelItemPlpp')) {
					// Concerns the item
					allEligibleValuesForFilter = pipesToDisplay
						.filter(aPipe => aPipe['carbonSteelItemPlpp'])
						.map(aPipe => {
							return String(aPipe['carbonSteelItemPlpp'][key.replace('carbonSteelItemPlpp.', '')])
						})
				} else {
					// Concerns the pipe
					if (key.startsWith('carbonSteelCertificate')) {
						allEligibleValuesForFilter = pipesToDisplay
							.filter(aPipe => aPipe['carbonSteelCertificate'])
							.map(aPipe => {
								return String(aPipe['carbonSteelCertificate'][key.replace('carbonSteelCertificate.', '')])
							})
					} else if (key.startsWith('carbonSteelHeat')) {
						if (key.startsWith('carbonSteelHeat.chemicalAnalysis')) {
							allEligibleValuesForFilter = pipesToDisplay
								.filter(aPipe => aPipe['carbonSteelHeat'] && aPipe['carbonSteelHeat']['chemicalAnalysis'])
								.map(aPipe => {
									return String(
										aPipe['carbonSteelHeat']['chemicalAnalysis'][
											key.replace('carbonSteelHeat.chemicalAnalysis.', '')
										]
									)
								})
						} else if (key.startsWith('carbonSteelHeat.tensileTest')) {
							allEligibleValuesForFilter = pipesToDisplay
								.filter(aPipe => aPipe['carbonSteelHeat'] && aPipe['carbonSteelHeat']['tensileTest'])
								.map(aPipe => {
									return String(
										aPipe['carbonSteelHeat']['tensileTest'][key.replace('carbonSteelHeat.tensileTest.', '')]
									)
								})
						} else if (key.startsWith('carbonSteelHeat.impactTest')) {
							allEligibleValuesForFilter = pipesToDisplay
								.filter(aPipe => aPipe['carbonSteelHeat'] && aPipe['carbonSteelHeat']['impactTest'])
								.map(aPipe => {
									return String(
										aPipe['carbonSteelHeat']['impactTest'][key.replace('carbonSteelHeat.impactTest.', '')]
									)
								})
						} else if (key.startsWith('carbonSteelHeat.hardnessTest')) {
							allEligibleValuesForFilter = pipesToDisplay
								.filter(aPipe => aPipe['carbonSteelHeat'] && aPipe['carbonSteelHeat']['hardnessTest'])
								.map(aPipe => {
									return String(
										aPipe['carbonSteelHeat']['hardnessTest'][key.replace('carbonSteelHeat.hardnessTest.', '')]
									)
								})
						} else {
							allEligibleValuesForFilter = pipesToDisplay
								.filter(aPipe => aPipe['carbonSteelHeat'])
								.map(aPipe => {
									return String(aPipe['carbonSteelHeat'][key.replace('carbonSteelHeat.', '')])
								})
						}
					} else {
						allEligibleValuesForFilter = pipesToDisplay.map(aPipe => {
							return String(aPipe[key])
						})
					}
				}

				// Convert to a set in order to remove duplicates
				const allEligibleValuesForFilterSet: Set<string> = new Set(allEligibleValuesForFilter)

				value.values = value.values.filter((v: string) => {
					return allEligibleValuesForFilterSet.has(v)
				})

				if (value.values.length === 0) {
					filteredColumnMap.delete(key)
				}
			}
		})
	}
}

const sortItem = (a: any, b: any) => {
	return `${a.itemDescription}`.localeCompare(`${b.itemDescription}`) === 0
		? a.vallourecItemReference - b.vallourecItemReference
		: `${a.itemDescription}`.localeCompare(`${b.itemDescription}`)
}

const sortTests = (a: any, b: any) => {
	const testTypeOrder = [
		'HEAT CHEMICAL ANALYSIS',
		'PRODUCT CHEMICAL ANALYSIS',
		'TENSILE TEST RESULTS',
		'IMPACT TEST RESULTS',
		'HARDNESS TEST RESULTS'
	]

	return (
		a.carbonSteelHeat.number - b.carbonSteelHeat.number ||
		testTypeOrder.indexOf(a.carbonSteelHeat.testType) - testTypeOrder.indexOf(b.carbonSteelHeat.testType) ||
		(a.carbonSteelHeat.sampleNumber && b.carbonSteelHeat.sampleNumber
			? a.carbonSteelHeat.sampleNumber.localeCompare(b.carbonSteelHeat.sampleNumber)
			: -1)
	)
}

const applyFilter = (tab: any, values: any, min: any, max: any, key: string) => {
	return tab.filter((row: any) => {
		let val = retrieveValueFromStringKey(key, row)

		if (values.length > 0 && values[0] !== '') {
			return values.includes(val)
		} else {
			if (min.length > 0 && max.length > 0) {
				return +val >= +min && +val <= +max
			} else if (min.length > 0 && max.length === 0) {
				return +val >= +min
			} else if (min.length === 0 && max.length > 0) {
				return +val <= +max
			} else {
				return val
			}
		}
	})
}
const getHeatFilter = (dataToDisplay: any) => {
	const heatNumbers = dataToDisplay
		.map(plppData => plppData.carbonSteelHeat.number)
		.filter(function (value, index, array) {
			// Remove duplicates
			return array.indexOf(value) === index
		})

	return {
		values: heatNumbers,
		min: '',
		max: ''
	}
}

export const getSelectedColumns = (state: State): any[] => {
	return state.plppColumns.map(col => ({ label: col.key, position: col.position, visible: col.visible }))
}

export const getColumnsToSaveInTemplate = (state: State): any[] => {
	// initial state of columns
	const defaultTemplateColumns = initPlppColumns()
	// get differences between default and actual configuration to set up the template
	return getDifference(state.plppColumns, defaultTemplateColumns).map(col => ({
		label: col.key,
		position: col.position,
		visible: col.visible
	}))
}

export const getDifference = (array1, array2) => {
	return array1.filter(object1 => {
		return (
			array2.findIndex(
				object2 =>
					((object1.key && object2.key && object1.key === object2.key) ||
						object1.key === object2.label ||
						(object1.label && object2.label && object1.label === object2.label)) &&
					object1.position === object2.position &&
					object1.visible === object2.visible
			) === -1
		)
	})
	//
}
