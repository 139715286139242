/** @format */

import { createStyles, makeStyles } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

const useStyles = makeStyles(() =>
	createStyles({
		icon_style: {
			width: '32px',
			height: '32px',
			color: '#1A2981',
			opacity: '1'
		}
	})
)

/**
 * Icon Information
 */
const IconInformation = (props: {}) => {
	const classes = useStyles()

	return <InfoOutlinedIcon className={classes.icon_style} />
}

export default IconInformation
