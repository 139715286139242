export function sortByPosition(a: any, b: any) : number {
        if (a.position === -1 && b.position === -1){
            return 0
        } else if (a.position === -1){
            return 1
        } else if (b === -1){
            return -1
        } else {
            return a.position - b.position
        }
}